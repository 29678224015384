import Debug from 'debug';

const debug = Debug('store:extensions:get-query'); // eslint-disable-line

export const getQuery = (getters, query, id = null) => {
  const storeFilter = id ? getters.filter(id) || {} : getters.filter || {};
  const storeSort = id ? getters.sort(id) || {} : getters.sort || {};
  const storePagination = id ? getters.pagination(id) || {} : getters.pagination || {};

  if (query?.filter && Object.keys(query.filter).length === 0) {
    return { filter: {}, sort: [], page: 1 };
  }

  return {
    filter: { ...storeFilter, ...query?.filter },
    sort: query?.sort || storeSort,
    page: query?.page || storePagination.current_page,
    ...(query?.force === true && { force: true }),
  };
};
