<template lang="pug">
div(:class="$style.root")
  div.h-100.d-flex.align-center.justify-md-space-between.justify-center
    <!-- 👉 Footer: left content -->
    span.d-flex.align-center
      | &copy;
      | #{new Date().getFullYear()}
      | Powered By
      a.text-primary.ms-1(
        :href="this.apps.home"
        target="_blank"
        rel="noopener noreferrer"
      ) {{ assets.title.toUpperCase() }}

    <!-- 👉 Footer: right content -->
    span.d-md-flex.gap-x-4.text-primary.d-none
      a(
        :href="`${this.api.docs}`"
        target="_blank"
      ) API Documentation
      a(
        :href="`${this.apps.home}/faq/`"
        target="noopener noreferrer"
      ) Support
</template>

<script src="./Footer.js"></script>
<style src="./Footer.scss" lang="scss" module></style>
