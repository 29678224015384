import Debug from 'debug';
import store from '@app/store';
import eventBus from './event-bus';
import { isEmptyObject } from './functions';

const debug = Debug('utils:api-reader');

const POST = 'POST';
const GET = 'GET';
const HEAD = 'HEAD';
const PATCH = 'PATCH';
const DELETE = 'DELETE';

function payloadRequest(method, path, query = {}, body = null) {
  const payload = body ? { payload: body } : null;
  return rawRequest(method, path, query, payload);
}

/* GLOBAL REQUESTS */
export function patch(route, object = null, query = {}) {
  return payloadRequest(PATCH, route, query, object);
}

export function post(route, object = null, query = {}) {
  return payloadRequest(POST, route, query, object);
}

export function get(route, query = {}) {
  return payloadRequest(GET, route, query);
}

export function remove(route, object = {}, query = {}) {
  return payloadRequest(DELETE, route, query, object);
}

export const getApiUrl = () => {
  const merchantUrl = process.env.BACKOFFICE_API_URL;
  if (merchantUrl !== null) {
    return `${merchantUrl}/`;
  }

  const { protocol, hostname } = window.location;

  if (hostname === 'staging.baltbit.com') {
    return `${protocol}//${hostname}/crypto-fusion/api/v1/`;
  }

  if (hostname.startsWith('panel.')) {
    return `${protocol}//${hostname.replace('panel.', 'api.')}/api/v1/`;
  }

  return `${protocol}//${hostname}/api/v1/`;
};

// Object to query string
const toQueryString = (obj, prefix = '') => {
  const queryString = [];

  Object.keys(obj).forEach(key => {
    const value = obj[key];
    const keyPath = prefix ? `${prefix}[${key}]` : key;

    if (value == null || value === '' || (Array.isArray(value) && value.length === 0) || (typeof value === 'object' && Object.keys(value).length === 0)) {
      return; // Skip empty values
    }

    if (typeof value === 'object' && !Array.isArray(value)) {
      queryString.push(toQueryString(value, keyPath));
    } else if (Array.isArray(value)) {
      queryString.push(`${keyPath}=${value.join(',')}`);
    } else {
      queryString.push(`${keyPath}=${value}`);
    }
  });

  return queryString.join('&');
};

function rawRequest(method, path, query = {}, body = null) {
  eventBus.emit('start');

  // Construct the full API endpoint URL
  const target = new URL(path, getApiUrl());

  // Ensure query is an object
  if (!isEmptyObject(query)) {
    target.search = `?${toQueryString(query)}`;
  }

  const headers = new Headers();
  headers.set('Content-Type', 'application/json');

  const apiKey = store.getters['auth/token'];
  if (apiKey) {
    headers.set('Authorization', `Bearer ${apiKey}`);
  }

  debug('[%s] %s%s query: %o, body: %o', method, path, apiKey ? '(authed) ' : '', query, body);

  return fetch(target.toString(), {
    method,
    headers,
    body: (method === GET || method === HEAD) ? undefined : JSON.stringify(body),
  })
    .then(res => {
      if (res.headers.get('Content-Type') !== 'application/json') {
        eventBus.emit('done');
        return res.blob();
      }

      return res.json()
        .then(response => {
          switch (response.meta.code) {
            case 200:
              eventBus.emit('done', response);
              return response;
            case 428:
              eventBus.emit('enable:otp');

              return null;
            case 449:
              eventBus.emit('done');

              // Emit the event for OTP input
              eventBus.emit('require:otp');

              // Wait for OTP input
              return new Promise(resolve => {
                eventBus.on('otp:entered', (otp) => {
                  body.payload.otp = otp;
                  return resolve(
                    rawRequest(method, path, query, body)
                      .then(res => {
                        eventBus.emit('confirmed:otp');
                        return res;
                      })
                      .catch(err => {
                        eventBus.emit('require:otp');
                        throw err;
                      }));
                });
              });
            case 401:
              eventBus.emit('logout:force');
            // eslint-disable-next-line no-fallthrough
            default:
              eventBus.emit('error', response);
              throw response;
          }
        });
    });
}

export default { post, patch, get, remove };
