import Debug from 'debug';
import eventBus from '@app/utils/event-bus';

const debug = Debug('otp-modal');

export default {
  data() {
    return {
      isOtpRequested: false,
      isOtpSubmitting: false,
      otpValue: '',
    };
  },
  watch: {
    otpValue: {
      immediate: true,
      handler(newValue) {
        if (newValue.length === 6) {
          this.onSubmit();
        }
      },
    },
  },
  methods: {
    handleOtpAdd() {
      this.isOtpRequested = true;
      this.isOtpSubmitting = false;
      this.otpValue = '';
    },
    handleOtpClose() {
      this.isOtpRequested = false;
      this.isOtpSubmitting = false;
      this.otpValue = '';
    },
    onSubmit() {
      this.isOtpSubmitting = true;

      eventBus.emit('otp:entered', this.otpValue);
    },
  },
  mounted() {
    debug('mounted');

    eventBus.on('require:otp', this.handleOtpAdd);
    eventBus.on('confirmed:otp', this.handleOtpClose);
  },
  unmounted() {
    eventBus.off('require:otp', this.handleOtpAdd);
    this.isOtpRequested = false;
    this.otpValue = '';
  },
};
