import Debug from 'debug';
import LoadingIndicator from '@app/components/loading-indicator/LoadingIndicator.vue';

const debug = Debug('layout-blank');

export default {
  components: {
    LoadingIndicator,
  },
  mounted() {
    debug('mounted');
  },
};
