import Debug from 'debug';
import api from '@app/utils/api-reader';
import eventBus from '@app/utils/event-bus';

const debug = Debug('utils:auth-service');

export default class AuthService {
  constructor(store, router) {
    this.store = store;
    this.router = router;

    eventBus.on('logout:force', this.logout.bind(this));
  }

  login(email, password) {
    debug('trying to login as %s', email);
    return api.post('user/login/backoffice', { email, password })
      .then(res => {
        this.store.dispatch('auth/setUser', res.data);

        return res;
      }).catch(err => {
        this.logout(false);
        throw err;
      });
  }

  logout(redirect = true) {
    debug('Logging out');

    if (this.store.getters['auth/token']) { // Invalidate current token at server side once
      api.post('user/logout');
    }

    // Clear token and user data from Vuex store
    this.store.dispatch('auth/setUser', null);
    this.store.dispatch('auth/reset', { user: null, otp: null });

    // Clear rest of user store
    this.store.dispatch('merchants/reset');
    this.store.dispatch('merchant-administrators/reset', { data: {} });
    this.store.dispatch('merchant-payment-systems/reset', { data: {} });
    this.store.dispatch('merchant-stocks/reset', { data: {} });
    this.store.dispatch('payment-systems/reset');
    this.store.dispatch('operations/reset');
    this.store.dispatch('operation-fees/reset', { data: {} });
    this.store.dispatch('operation-requests/reset', { data: {} });
    this.store.dispatch('transactions/reset');
    this.store.dispatch('transaction-requests/reset', { data: {} });
    this.store.dispatch('webhooks/reset');
    this.store.dispatch('services/reset', { data: {} });
    this.store.dispatch('tariffs/reset');
    this.store.dispatch('customers/reset');
    this.store.dispatch('customer-verifications/reset');
    this.store.dispatch('settings/reset');
    this.store.dispatch('node-networks/reset');
    this.store.dispatch('stocks/reset');
    this.store.dispatch('users/reset');

    if (redirect) {
      // Redirect to home or login page
      this.router.push({ name: 'login' });
    }
  }

  register(user) {
    debug('trying to register as %o', user);

    return api.post('user/register', user)
      .then(res => {
        this.store.dispatch('auth/setUser', res.data);

        return res;
      })
      .catch(err => {
        throw err;
      });
  }

  forgotPassword(user) {
    debug('trying to reset password as %o', user);

    return api.post('user/forgot-password', user)
      .then(res => {
        return res;
      })
      .catch(err => {
        throw err;
      });
  }

  resetPassword(user) {
    debug('trying to reset password as %o', user);

    return api.post('user/forgot-password/reset', user)
      .then(res => {
        return res;
      })
      .catch(err => {
        throw err;
      });
  }

  verifyEmail(verificationUrl) {
    debug('trying to verify email as %s', verificationUrl);

    return api.get(verificationUrl)
      .then(res => {
        return res;
      })
      .catch(err => {
        throw err;
      });
  }
};
