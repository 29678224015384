import * as MutationType from '@app/store/mutation-types';

export default {
  state: {
    pagination: {},
  },
  actions: {
    pagination({ commit }, pagination) {
      commit(MutationType.SET_PAGINATION, pagination);
    },
  },
  getters: {
    pagination: s => s.pagination,
    isPaginationChanged: s => (query) => {
      const page = query?.page ? parseInt(query.page, 10) : 1;
      return s.pagination.current_page !== page;
    },
  },
  mutations: {
    [MutationType.SET_PAGINATION](s, pagination) {
      s.pagination = pagination;
    },
  },
};
