// Merchant webhook
export const WEBHOOK_OPERATION_STATUS = 'operation.status';
export const WEBHOOK_TRANSACTION_STATUS = 'transaction.status';

// Merchant webhook template_class
export const WEBHOOK_TEMPLATE_CLASS_OPERATION = 'App\\Services\\Webhook\\OperationWebhook';
export const WEBHOOK_TEMPLATE_CLASS_TRANSACTION = 'App\\Services\\Webhook\\TransactionWebhook';

// Default entity statuses
export const STATUS_ACTIVE = 'active';
export const STATUS_DISABLED = 'disabled';
export const STATUS_CREATED = 'created';
export const STATUS_SUCCESS = 'success';
export const STATUS_PROCESSING = 'processing';
export const STATUS_FAILED = 'failed';
export const STATUS_AWAITING_CONFIRMATION = 'awaiting_confirmation';
export const STATUS_EXTERNAL_PROCESSING = 'external_processing';
export const STATUS_EXPIRED = 'expired';

// YesNoNone
export const TYPE_YES = 'yes';
export const TYPE_NO = 'no';

// Operation direction
export const DIRECTION_IN = 'in';
export const DIRECTION_OUT = 'out';
export const DIRECTION_UNKNOWN = 'unknown';

// Transaction types
export const TYPE_IN_BY_GATE = 'in_by_gate';
export const TYPE_IN_OUT_BY_GATE = 'in_out_by_gate';
export const TYPE_IN_OUT_REMOTE_BY_GATE = 'in_out_remote_by_gate';
export const TYPE_IN_DIRECT = 'in_direct';
export const TYPE_OUT_DIRECT = 'out_direct';
export const TYPE_OUT_CARD = 'out_card';
export const TYPE_EXCHANGE_STOCK = 'exchange_stock';

// Tariffs - action
export const TARIFF_ACTION_UNKNOWN = 'unknown';
export const TARIFF_ACTION_TRANSFER = 'transfer';
export const TARIFF_ACTION_DEPOSIT = 'deposit';
export const TARIFF_ACTION_WITHDRAW_TO_CARD = 'withdraw_to_card';
export const TARIFF_ACTION_STOCK_EXCHANGE = 'stock_exchange';
export const TARIFF_ACTION_PAY_VIA_PAYMENT_GATE = 'pay_via_payment_gate';

// Tariffs - type
export const TARIFF_TYPE_FIX = 'fix';
export const TARIFF_TYPE_PERCENT = 'percent';

// Tariffs - service
export const TARIFF_SERVICE_NODE_NETWORK = 'node_network';
export const TARIFF_SERVICE_PAYMENT_GATEWAY = 'payment_gateway';
export const TARIFF_SERVICE_STOCK = 'stock';
export const TARIFF_SERVICE_MANUAL = 'manual';

export const DEFAULT_AMOUNT = '0.00000000';
export const DEFAULT_AMOUNT_PRECISION = 8;

// User settings
export const USER_SETTING_BACKOFFICE_THEME = 'backoffice_theme';

// Tariffs - instructions
export const TARIFF_INSTRUCTION_REQUIRED = 'App\\Services\\Tariff\\Instruction\\Required';
export const TARIFF_INSTRUCTION_AMOUNT_BASED = 'App\\Services\\Tariff\\Instruction\\AmountBased';
export const TARIFF_INSTRUCTION_INCLUDE_CARD_ISO3_COUNTRY = 'App\\Services\\Tariff\\Instruction\\IncludeCardISO3Country';
export const TARIFF_INSTRUCTION_EXCLUDE_CARD_ISO3_COUNTRY = 'App\\Services\\Tariff\\Instruction\\ExcludeCardISO3Country';

// System Settings
export const JSON_SETTINGS_KEYS = [
  'FIAT_CURRENCIES',
];
