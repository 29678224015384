import * as MutationType from '@app/store/mutation-types';
import { LOADING_BY_KEY, RESET_STATE } from '@app/store/extension-loader';
import api from '@app/utils/api-reader';
import merge from 'lodash/merge';

const actions = {
  download({ commit, dispatch, getters }, service) {
    if (!getters.data(service).length) {
      dispatch('start', service);
      return api
        .get('backoffice/services', { service })
        .then(res => {
          commit(MutationType.GET_SERVICES, { service, data: res.data });
          dispatch('stop', service);
          return res;
        })
        .catch(err => { dispatch('stop'); throw err; });
    }
    return { data: getters.data(service) };
  },
};

const mutations = {
  [MutationType.GET_SERVICES](s, { service, data }) {
    s.data = {
      ...s.data,
      [service]: data,
    };
  },
};

export default merge({}, LOADING_BY_KEY, RESET_STATE, {
  state: {
    data: {},
  },
  getters: {
    data: s => (service) => s.data[service] || [],
  },
  mutations,
  actions,
  namespaced: true,
});
