import * as MutationType from '@app/store/mutation-types';
import { LOADING, PAGINATOR, FILTER, RESET_STATE } from '@app/store/extension-loader';
import api from '@app/utils/api-reader';
import merge from 'lodash/merge';
import Debug from 'debug';
import { getQuery } from '@app/store/extensions/get-query.js';

const debug = Debug('store:merchants'); // eslint-disable-line

const actions = {
  download({ commit, dispatch, getters }, query = null) {
    query = getQuery(getters, query);

    const shouldFetch =
      (!getters.isLoading && !getters.data.length) ||
      getters.isPaginationChanged(query) ||
      getters.isRequestChanged(query);

    if (shouldFetch) {
      dispatch('start');
      return api
        .get('backoffice/settings', query)
        .then(res => {
          commit(MutationType.GET_MERCHANTS, res.data);
          dispatch('pagination', res.meta.pagination);
          dispatch('sortBy', query?.sort ?? []);
          dispatch('filterBy', query?.filter ?? {});
          dispatch('stop');
          return res;
        })
        .catch(err => { dispatch('stop'); throw err; });
    }
    return { data: getters.data, meta: { pagination: getters.pagination } };
  },
  downloadById({ commit, dispatch, getters }, id) {
    dispatch('start');
    return api
      .get(`backoffice/settings/${id}/full`)
      .then(res => {
        commit(MutationType.UPDATE_MERCHANTS, res.data);
        dispatch('stop');
        return res;
      })
      .catch(err => { dispatch('stop'); throw err; });
  },
  create({ commit, dispatch }, payload) {
    dispatch('start');
    return api
      .post('backoffice/settings/create', payload)
      .then(res => {
        commit(MutationType.CREATE_MERCHANTS, res.data);
        commit(`auth/${MutationType.SET_USER_MERCHANT}`, res.data, { root: true });
        dispatch('stop');
        return res;
      })
      .catch(err => { dispatch('stop'); throw err; });
  },
  updateTheme({ commit, dispatch }, payload) {
    dispatch('start');

    const id = payload.id;
    delete payload.id;

    return api
      .patch(`backoffice/settings/update/${id}/theme`, payload)
      .then(res => {
        commit(MutationType.UPDATE_MERCHANTS, res.data);
        dispatch('stop');
        return res;
      })
      .catch(err => { dispatch('stop'); throw err; });
  },
  updateSecret({ commit, dispatch }, id) {
    dispatch('start');
    return api
      .patch(`backoffice/settings/update/${id}/secret`)
      .then(res => {
        commit(MutationType.UPDATE_MERCHANTS, res.data);
        dispatch('stop');
        return res;
      })
      .catch(err => { dispatch('stop'); throw err; });
  },
  update({ commit, dispatch }, payload) {
    dispatch('start');

    const id = payload.id;
    delete payload.id;

    return api
      .patch(`backoffice/settings/update/${id}`, payload)
      .then(res => {
        commit(MutationType.UPDATE_MERCHANTS, res.data);
        dispatch('stop');
        return res;
      })
      .catch(err => { dispatch('stop'); throw err; });
  },
  delete({ commit, dispatch }, id) {
    dispatch('start');
    return api
      .remove(`backoffice/settings/delete/${id}`)
      .then(res => {
        commit(MutationType.DELETE_MERCHANTS, id);
        dispatch('stop');
        return res;
      })
      .catch(err => { dispatch('stop'); throw err; });
  },
};

const mutations = {
  [MutationType.GET_MERCHANTS](s, data) {
    s.data = data;
  },
  [MutationType.UPDATE_MERCHANTS](s, data) {
    const index = s.data.findIndex(record => record.id === data.id);

    if (index !== -1) {
      s.data.splice(index, 1, data);
    }
  },
  [MutationType.CREATE_MERCHANTS](s, data) {
    s.data.unshift(data);
  },
  [MutationType.DELETE_MERCHANTS](s, id) {
    s.data = s.data.filter(record => record.id !== id);
  },
};

export default merge({}, LOADING, FILTER, PAGINATOR, RESET_STATE, {
  state: {
    data: [],
  },
  getters: {
    data: s => s.data,
  },
  mutations,
  actions,
  namespaced: true,
});
