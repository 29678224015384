import Debug from 'debug';
import { mapGetters } from 'vuex';

const debug = Debug('profile');

export default {
  computed: {
    ...mapGetters('configuration', [
      'apps',
    ]),
    ...mapGetters('auth', [
      'user',
    ]),
    getUserName() {
      return this.user?.name;
    },
    getUserEmail() {
      return this.user?.email;
    },
    getAvatar() {
      const profile = this.user?.user_profile;

      if (profile && profile.name && profile.surname) {
        const nameInitial = profile.name.charAt(0).toUpperCase();
        const surnameInitial = profile.surname.charAt(0).toUpperCase();
        return `${nameInitial}${surnameInitial}`;
      }

      return 'NF';
    },
  },
  methods: {
    logout() {
      this.$authService.logout();
    },
  },
  mounted() {
    debug('mounted');
  },
};
