import * as MutationType from '@app/store/mutation-types';
import { LOADING_BY_KEY, RESET_STATE } from '@app/store/extension-loader';
import api from '@app/utils/api-reader';
import merge from 'lodash/merge';
import Debug from 'debug';

const debug = Debug('store:merchant-administrators'); // eslint-disable-line

const actions = {
  download({ commit, dispatch, getters }, { id, query = null }) {
    if (!getters.data(id).length) {
      dispatch('start', id);
      return api
        .get(`backoffice/admin/merchant/administrators/${id}`, query)
        .then(res => {
          commit(MutationType.GET_MERCHANT_ADMINS, { id, data: res.data });
          dispatch('stop', id);
          return res;
        })
        .catch(err => { dispatch('stop', id); throw err; });
    }
    return { data: getters.data(id) };
  },
  add({ commit, dispatch }, { id, payload }) {
    dispatch('start', id);
    return api
      .post(`backoffice/admin/merchant/administrators/${id}/add`, payload)
      .then(res => {
        commit(MutationType.ADD_MERCHANT_ADMIN, { id, data: res.data });
        dispatch('stop', id);
        return res;
      })
      .catch(err => { dispatch('stop', id); throw err; });
  },
  delete({ commit, dispatch }, { id, payload }) {
    dispatch('start', id);
    return api
      .remove(`backoffice/admin/merchant/administrators/${id}/delete`, payload)
      .then(res => {
        commit(MutationType.DELETE_MERCHANT_ADMIN, { id, data: res.data });
        dispatch('stop', id);
        return res;
      })
      .catch(err => { dispatch('stop', id); throw err; });
  },
};

const mutations = {
  [MutationType.GET_MERCHANT_ADMINS](s, { id, data }) {
    s.data = {
      ...s.data,
      [id]: data,
    };
  },
  [MutationType.ADD_MERCHANT_ADMIN](s, { id, data }) {
    s.data[id].unshift(data);
  },
  [MutationType.DELETE_MERCHANT_ADMIN](s, { id, data }) {
    s.data[id] = s.data[id].filter(record => record.id !== data.id);
  },
};

export default merge({}, LOADING_BY_KEY, RESET_STATE, {
  state: {
    data: {},
  },
  getters: {
    data: s => (id) => s.data[id] || [],
  },
  mutations,
  actions,
  namespaced: true,
});
