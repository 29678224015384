import { useTheme } from 'vuetify';
import { watch } from 'vue';
import { useCycleList } from '@vueuse/core';
import Debug from 'debug';
import { mapGetters, mapActions } from 'vuex';
import * as DataConstants from '@app/constants/field-data.js';

const debug = Debug('layout-switcher');

export default {
  data() {
    return {
      themes: [
        {
          name: 'light',
          icon: 'ri-sun-line',
        },
        {
          name: 'dark',
          icon: 'ri-moon-clear-line',
        },
      ],
    };
  },
  created() {
    const { name: themeName, global: globalTheme } = useTheme();
    const { next: getNextThemeName } = useCycleList(this.themeNames, { initialValue: themeName.value });

    globalTheme.name.value = this.currentThemeName;

    this.changeTheme = () => {
      globalTheme.name.value = getNextThemeName();
    };

    watch(() => globalTheme.name.value, val => {
      this.updateSettings([
        {
          key: DataConstants.USER_SETTING_BACKOFFICE_THEME,
          value: globalTheme.name.value,
        },
      ]);
    });
  },
  computed: {
    ...mapGetters('auth', [
      'user',
    ]),
    themeNames() {
      return this.themes.map(t => t.name);
    },
    currentIcon() {
      const theme = this.themes.find(t => t.name === this.currentThemeName);
      return theme ? theme.icon : '';
    },
    currentThemeName() {
      const themeSetting = this.user?.settings?.find(setting => setting.key === DataConstants.USER_SETTING_BACKOFFICE_THEME);

      return themeSetting?.value || 'dark';
    },
  },
  methods: {
    ...mapActions('auth', [
      'updateSettings',
    ]),
    changeTheme() {
      const { global: globalTheme } = useTheme();
      const { next: getNextThemeName } = useCycleList(this.themeNames, { initialValue: this.currentThemeName });
      globalTheme.name.value = getNextThemeName();
    },
  },
  mounted() {
    debug('mounted');
  },
};
