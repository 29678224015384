import { format } from 'date-fns';
import * as DataConstants from '@app/constants/field-data.js';
import Debug from 'debug';
import { isObject, cloneDeep, kebabCase, deburr, isArray } from 'lodash';

const debug = Debug('utils:functions'); // eslint-disable-line

export const isEmptyObject = (o) => {
  if (!isObject(o)) {
    return true;
  }
  return Object.keys(o).every(x => o[x] === '' || o[x] === null);
};

export const isEmptyArray = (a) => {
  if (!isArray(a)) {
    return true;
  }
  return a.length === 0;
};

export const isNumeric = (n) => !isNaN(n) && !isNaN(parseFloat(n));

export const formatDate = (date, dateFormat = 'yyyy-MM-dd HH:mm:ss') => {
  if (date === null) {
    return 'NaN';
  }
  return format(new Date(date), dateFormat);
};

export const formatUnixDate = (timestamp, dateFormat = 'yyyy-MM-dd HH:mm:ss') => {
  if (!timestamp || isNaN(timestamp)) {
    return 'NaN';
  }
  return format(new Date(timestamp * 1000), dateFormat);
};

export const resolveStatus = (status) => {
  switch (status) {
    case DataConstants.STATUS_FAILED:
    case DataConstants.STATUS_EXPIRED:
      return { color: 'error', text: status };
    case DataConstants.STATUS_SUCCESS:
    case DataConstants.STATUS_ACTIVE:
      return { color: 'success', text: status };
    case DataConstants.STATUS_PROCESSING:
    case DataConstants.STATUS_EXTERNAL_PROCESSING:
    case DataConstants.STATUS_AWAITING_CONFIRMATION:
      return { color: 'warning', text: status };
    case DataConstants.STATUS_DISABLED:
      return { color: 'info', text: status };
    case DataConstants.STATUS_CREATED:
    default:
      return { color: 'default', text: status };
  }
};

export const truncateTooltipText = (text, length) => {
  if (!text || text.length <= 0) {
    return {
      text: '-',
      textFull: '',
      tooltip: false,
    };
  }

  return {
    text: text.length > length ? text.substring(0, length) + '...' : text,
    textFull: text,
    tooltip: text.length > length,
  };
};

export const parseTextOrEmpty = (text, empty = '-') => {
  return text.length >= 1 ? text : empty;
};

export const slugify = (string) => {
  return kebabCase(deburr(string));
};

export const download = (blob, filename = '', download = false, target = '_blank') => {
  const url = URL.createObjectURL(blob);
  if (!download) {
    window.open(url, target);
  } else {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
  }
  URL.revokeObjectURL(url);
};

export const getServiceRoute = operation => {
  switch (operation.service) {
    case DataConstants.TARIFF_SERVICE_NODE_NETWORK:
      return { name: 'node-networks-view', params: { id: operation.service_id } };
    case DataConstants.TARIFF_SERVICE_PAYMENT_GATEWAY:
      return { name: 'payment-systems-view', params: { id: operation.service_id } };
    case DataConstants.TARIFF_SERVICE_STOCK:
      return { name: 'stocks-view', params: { id: operation.service_id } };
    default:
      return null;
  }
};

export const bindFilterToQuery = (filter) => {
  const result = {};

  Object.keys(filter).forEach((key) => {
    const value = filter[key];

    if (Array.isArray(value) && value.length === 0) {
      result[key] = null; // Set empty arrays to null
    } else if (typeof value === 'object' && value !== null && value.key) {
      if (Array.isArray(value.value) && value.value.length > 0) {
        result[value.key] = value.value.join(',');
      } else if (value.value !== '' && value.value !== null) {
        result[value.key] = value.value;
      }
    } else if (value !== '' && value !== null) {
      if (key === 'created_at' && typeof value === 'object') {
        if (value.from !== null && value.to !== null) {
          result[`${key}.between`] = `${value.from},${value.to}`;
        } else if (value.from !== null) {
          result[`${key}.from`] = value.from;
        } else if (value.to !== null) {
          result[`${key}.to`] = value.to;
        }
      } else if (Array.isArray(value) && value.length > 0) {
        result[key] = value.join(',');
      } else {
        result[key] = value;
      }
    }
  });

  return result;
};

export const bindQueryToFilter = (source) => {
  const params = new URLSearchParams(window.location.search);
  const filter = cloneDeep(source);

  params.forEach((value, key) => {
    if (key.startsWith('filter[') && key.endsWith(']')) {
      const paramKey = key.slice(7, -1);

      const dateMatch = paramKey.match(/^(.+?)\.(from|to)$/);
      if (dateMatch) {
        const [, baseKey, dateType] = dateMatch;
        if (filter[baseKey] && typeof filter[baseKey] === 'object') {
          filter[baseKey][dateType] = isNumeric(value) ? Number(value) : value;
        }
        return;
      }

      Object.entries(filter).forEach(([field, fieldValue]) => {
        if (fieldValue && typeof fieldValue === 'object' && fieldValue.key === paramKey) {
          filter[field].value = value.includes(',')
            ? value.split(',').map((v) => (isNumeric(v) ? Number(v) : v))
            : isNumeric(value) ? Number(value) : value;
        } else if (field === paramKey) {
          filter[field] = value.includes(',')
            ? value.split(',').map((v) => (isNumeric(v) ? Number(v) : v))
            : isNumeric(value) ? Number(value) : value;
        }
      });
    }
  });

  return filter;
};

export const updateQueryParams = (newParams) => {
  const params = new Map();

  Object.entries(newParams).forEach(([key, value]) => {
    if (key === 'page' && value) {
      params.set(key, String(value));
    } else if (key === 'sort') {
      if (Array.isArray(value) && value.length > 0) {
        params.set(key, value.join(','));
      } else if (typeof value === 'string' && value.trim() !== '') {
        params.set(key, value);
      }
    } else if (value && typeof value === 'object' && Object.keys(value).length > 0) {
      const validEntries = Object.entries(value)
        .filter(([_, subValue]) => subValue && subValue.length > 0);

      if (validEntries.length > 0) {
        validEntries.forEach(([subKey, subValue]) => {
          const paramKey = `${key}[${subKey}]`;

          if (Array.isArray(subValue)) {
            params.set(paramKey, subValue.join(',')); // No encoding
          } else {
            params.set(paramKey, subValue); // No encoding
          }
        });
      }
    }
  });

  const queryString = Array.from(params.entries())
    .map(([k, v]) => `${k}=${v}`) // No encoding
    .join('&');

  const newUrl = queryString ? `${window.location.pathname}?${queryString}` : window.location.pathname;

  if (newUrl !== window.location.href) {
    window.history.pushState({}, '', newUrl);
  }
};
