import Debug from 'debug';
import qs from 'qs';
import * as MutationType from '@app/store/mutation-types.js';
import { updateQueryParams } from '@app/utils/functions.js';

const debug = Debug('store:subscriber'); // eslint-disable-line

export const storeSubscriber = (store) => {
  store.subscribeAction({
    before: (action, state) => {
      const storeName = window.location.pathname.split('/')[1] || null;

      if (action.type === `${storeName}/download`) {
        const searchParams = new URLSearchParams(window.location.search);
        const query = qs.parse(searchParams.toString(), { ignoreQueryPrefix: true });

        const filter = query.filter || null;
        const sort = query.sort || null;
        const page = parseInt(query?.page ?? state[storeName]?.pagination?.current_page ?? 1, 10);

        if (filter) {
          store.commit(`${storeName}/${MutationType.SET_FILTERS}`, filter);
        }

        if (sort) {
          store.commit(`${storeName}/${MutationType.SET_SORT_BY}`, sort);
        }

        store.commit(`${storeName}/${MutationType.SET_PAGINATION}`, { ...state[storeName].pagination, current_page: page });
      }
    },
    after: (action, state) => {
      const storeName = window.location.pathname.split('/')[1] || null;

      if (action.type === `${storeName}/download`) {
        const { filter, sort, pagination } = state[storeName];

        updateQueryParams({
          filter,
          sort,
          page: pagination?.current_page,
        });
      }
    },
  });
};
