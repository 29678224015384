import * as MutationType from '@app/store/mutation-types';
import { LOADING } from '@app/store/extension-loader';
import api from '@app/utils/api-reader';
import merge from 'lodash/merge';

const actions = {
  download({ commit, dispatch, getters }, query = null) {
    if (!getters.data.length) {
      dispatch('start');
      return api
        .get('backoffice/admin/content', query)
        .then(res => {
          commit(MutationType.GET_CONTENTS, res.data);
          dispatch('stop');
          return res;
        })
        .catch(err => { dispatch('stop'); throw err; });
    }
    return { data: getters.data };
  },
  create({ commit, dispatch }, payload) {
    dispatch('start');

    return api
      .post('backoffice/admin/content/create', payload)
      .then(res => {
        commit(MutationType.CREATE_CONTENT, res.data);
        dispatch('stop');
        return res;
      })
      .catch(err => { dispatch('stop'); throw err; });
  },
  update({ commit, dispatch }, payload) {
    dispatch('start');

    const id = payload.id;
    delete payload.id;

    return api
      .patch(`backoffice/admin/content/update/${id}`, payload)
      .then(res => {
        commit(MutationType.UPDATE_CONTENT, res.data);
        dispatch('stop');
        return res;
      })
      .catch(err => { dispatch('stop'); throw err; });
  },
  delete({ commit, dispatch }, payload) {
    dispatch('start');

    const id = payload.id;
    delete payload.id;

    return api
      .remove(`backoffice/admin/content/delete/${id}`)
      .then(res => {
        commit(MutationType.DELETE_CONTENT, id);
        dispatch('stop');
        return res;
      })
      .catch(err => { dispatch('stop'); throw err; });
  },
};

const mutations = {
  [MutationType.GET_CONTENTS](s, data) {
    s.data = data;
  },
  [MutationType.CREATE_CONTENT](s, data) {
    s.data.unshift(data);
  },
  [MutationType.UPDATE_CONTENT](s, data) {
    const index = s.data.findIndex(record => record.type === data.type);

    if (index !== -1) {
      s.data.splice(index, 1, data);
    }
  },
  [MutationType.DELETE_CONTENT](s, id) {
    s.data = s.data.filter(record => record.id !== id);
  },
};

export default merge({}, LOADING, {
  state: {
    data: [],
  },
  getters: {
    data: s => s.data,
  },
  mutations,
  actions,
  namespaced: true,
});
