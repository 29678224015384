import * as MutationType from '@app/store/mutation-types';
import { LOADING_BY_KEY, RESET_STATE } from '@app/store/extension-loader';
import api from '@app/utils/api-reader';
import merge from 'lodash/merge';
import Debug from 'debug';

const debug = Debug('store:merchant-stocks'); // eslint-disable-line

const actions = {
  download({ commit, dispatch, getters }, { id, query = null }) {
    if (!getters.data(id).length) {
      dispatch('start', id);
      return api
        .get(`backoffice/admin/merchant/stocks/${id}`, query)
        .then(res => {
          commit(MutationType.GET_MERCHANT_STOCKS, { id, data: res.data });
          dispatch('stop', id);
          return res;
        })
        .catch(err => { dispatch('stop', id); throw err; });
    }
    return { data: getters.data(id) };
  },
  create({ commit, dispatch }, { id, payload }) {
    dispatch('start', id);
    return api
      .post(`backoffice/admin/merchant/stocks/${id}/create`, payload)
      .then(res => {
        commit(MutationType.CREATE_MERCHANT_STOCK, { id, data: res.data });
        dispatch('stop', id);
        return res;
      })
      .catch(err => { dispatch('stop', id); throw err; });
  },
  update({ commit, dispatch }, { id, payload }) {
    dispatch('start', id);
    return api
      .patch(`backoffice/admin/merchant/stocks/${id}/update`, payload)
      .then(res => {
        commit(MutationType.UPDATE_MERCHANT_STOCK, { id, data: res.data });
        dispatch('stop', id);
        return res;
      })
      .catch(err => { dispatch('stop', id); throw err; });
  },
  delete({ commit, dispatch }, { id, merchantStockId }) {
    dispatch('start', id);
    return api
      .remove(`backoffice/admin/merchant/stocks/${id}/delete/${merchantStockId}`)
      .then(res => {
        commit(MutationType.DELETE_MERCHANT_STOCK, { id, merchantStockId });
        dispatch('stop', id);
        return res;
      })
      .catch(err => { dispatch('stop', id); throw err; });
  },
};

const mutations = {
  [MutationType.GET_MERCHANT_STOCKS](s, { id, data }) {
    s.data = {
      ...s.data,
      [id]: data,
    };
  },
  [MutationType.CREATE_MERCHANT_STOCK](s, { id, data }) {
    s.data[id].unshift(data);
  },
  [MutationType.UPDATE_MERCHANT_STOCK](s, { id, data }) {
    const index = s.data[id].findIndex(record => record.id === data.id);

    if (index !== -1) {
      s.data[id].splice(index, 1, data);
    }
  },
  [MutationType.DELETE_MERCHANT_STOCK](s, { merchantId, merchantStockId }) {
    s.data[merchantId] = s.data[merchantId].filter(record => record.id !== merchantStockId);
  },
};

export default merge({}, LOADING_BY_KEY, RESET_STATE, {
  state: {
    data: {},
  },
  getters: {
    data: s => (id) => s.data[id] || [],
  },
  mutations,
  actions,
  namespaced: true,
});
