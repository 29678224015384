import store from './store';
import Layout from './components/layout/Layout.vue';
import Debug from 'debug';
import * as MutationType from './store/mutation-types';
import { createRouter, createWebHistory } from 'vue-router';
import eventBus from '@app/utils/event-bus.js';

const debug = Debug('router');

const unauthorized = ['login', 'register', 'forgot-password', 'reset-password'];

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('./views/home/home.vue'),
    meta: { layout: Layout },
  },
  {
    path: '/profile-settings',
    component: () => import('./views/profile-settings/profile-settings.vue'),
    meta: { layout: Layout },
    redirect: { name: 'account' },
    children: [
      {
        path: 'account',
        name: 'account',
        component: () => import('./views/profile-settings/tabs/account-details/account-settings.vue'),
      },
      {
        path: 'opt-and-security',
        name: 'opt-and-security',
        component: () => import('./views/profile-settings/tabs/otp-and-security/otp-and-security.vue'),
      },
    ],
  },
  {
    path: '/transactions',
    component: () => import('./views/transactions/transactions.vue'),
    meta: { layout: Layout },
    redirect: { name: 'transactions' },
    children: [
      {
        path: '',
        name: 'transactions',
        component: () => import('./views/transactions/list/list.vue'),
      },
      {
        path: ':id',
        name: 'transactions-view',
        component: () => import('./views/transactions/view/view.vue'),
        redirect: { name: 'transaction-details' },
        children: [
          {
            path: 'details',
            name: 'transaction-details',
            component: () => import('./views/transactions/view/details/details.vue'),
          },
          {
            path: 'operations',
            name: 'transaction-operations',
            component: () => import('./views/transactions/view/operations/operations.vue'),
          },
          {
            path: 'webhooks',
            name: 'transaction-webhooks',
            component: () => import('./views/transactions/view/webhooks/webhooks.vue'),
          },
          {
            path: 'requests',
            name: 'transaction-requests',
            component: () => import('./views/transactions/view/requests/requests.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/operations',
    component: () => import('./views/operations/operations.vue'),
    meta: { layout: Layout },
    redirect: { name: 'operations' },
    children: [
      {
        path: '',
        name: 'operations',
        component: () => import('./views/operations/list/list.vue'),
      },
    ],
  },
  {
    path: '/customers',
    component: () => import('./views/customers/customers.vue'),
    meta: { layout: Layout },
    redirect: { name: 'customers' },
    children: [
      {
        path: '',
        name: 'customers',
        component: () => import('./views/customers/list/list.vue'),
      },
      {
        path: ':id',
        name: 'customers-view',
        component: () => import('./views/customers/view/view.vue'),
        redirect: { name: 'customers-details' },
        children: [
          {
            path: 'details',
            name: 'customers-details',
            component: () => import('./views/customers/view/details/details.vue'),
          },
          {
            path: 'balances',
            name: 'customers-balances',
            component: () => import('./views/customers/view/balances/balances.vue'),
          },
          {
            path: 'verification',
            name: 'customers-verification',
            component: () => import('./views/customers/view/verification/verification.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/payment-systems',
    component: () => import('./views/payment-system/payment-system.vue'),
    meta: { layout: Layout },
    redirect: { name: 'payment-systems' },
    children: [
      {
        path: '',
        name: 'payment-systems',
        component: () => import('./views/payment-system/list/list.vue'),
      },
      {
        path: ':id',
        name: 'payment-systems-view',
        component: () => import('./views/payment-system/view/view.vue'),
        redirect: { name: 'payment-systems-details' },
        children: [
          {
            path: 'details',
            name: 'payment-systems-details',
            component: () => import('./views/payment-system/view/details/details.vue'),
          },
          {
            path: 'currencies',
            name: 'payment-systems-currencies',
            component: () => import('./views/payment-system/view/currencies/currencies.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/stocks',
    component: () => import('./views/stock/stock.vue'),
    meta: { layout: Layout },
    redirect: { name: 'stocks' },
    children: [
      {
        path: '',
        name: 'stocks',
        component: () => import('./views/stock/list/list.vue'),
      },
      {
        path: ':id',
        name: 'stocks-view',
        component: () => import('./views/stock/view/view.vue'),
        redirect: { name: 'stocks-details' },
        children: [
          {
            path: 'details',
            name: 'stocks-details',
            component: () => import('./views/stock/view/details/details.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/tariffs',
    component: () => import('./views/tariffs/tariffs.vue'),
    meta: { layout: Layout },
    redirect: { name: 'tariffs' },
    children: [
      {
        path: '',
        name: 'tariffs',
        component: () => import('./views/tariffs/list/list.vue'),
      },
    ],
  },
  {
    path: '/queue-queued',
    component: () => import('./views/queued/queued.vue'),
    meta: { layout: Layout },
    redirect: { name: 'queue-queued' },
    children: [
      {
        path: '',
        name: 'queue-queued',
        component: () => import('./views/queued/list/list.vue'),
      },
    ],
  },
  {
    path: '/queue-failed',
    component: () => import('@app/views/queue-failed/queue-failed.vue'),
    meta: { layout: Layout },
    redirect: { name: 'queue-failed' },
    children: [
      {
        path: '',
        name: 'queue-failed',
        component: () => import('@app/views/queue-failed/list/list.vue'),
      },
    ],
  },
  {
    path: '/activity-log',
    component: () => import('./views/activity-log/activity-log.vue'),
    meta: { layout: Layout },
    redirect: { name: 'activity-log' },
    children: [
      {
        path: '',
        name: 'activity-log',
        component: () => import('./views/activity-log/list/list.vue'),
      },
    ],
  },
  {
    path: '/http-requests',
    component: () => import('./views/http-request/http-request.vue'),
    meta: { layout: Layout },
    redirect: { name: 'http-requests' },
    children: [
      {
        path: '',
        name: 'http-requests',
        component: () => import('./views/http-request/list/list.vue'),
      },
    ],
  },
  {
    path: '/countries',
    component: () => import('./views/countries/countries.vue'),
    meta: { layout: Layout },
    redirect: { name: 'countries' },
    children: [
      {
        path: '',
        name: 'countries',
        component: () => import('./views/countries/list/list.vue'),
      },
    ],
  },
  {
    path: '/currencies',
    component: () => import('./views/currencies/currencies.vue'),
    meta: { layout: Layout },
    redirect: { name: 'currencies' },
    children: [
      {
        path: '',
        name: 'currencies',
        component: () => import('./views/currencies/list/list.vue'),
      },
    ],
  },
  {
    path: '/settings',
    component: () => import('./views/settings/settings.vue'),
    meta: { layout: Layout },
    redirect: { name: 'settings' },
    children: [
      {
        path: '',
        name: 'settings',
        component: () => import('./views/settings/list/list.vue'),
      },
    ],
  },
  {
    path: '/contents',
    component: () => import('./views/contents/contents.vue'),
    meta: { layout: Layout },
    redirect: { name: 'contents' },
    children: [
      {
        path: '',
        name: 'contents',
        component: () => import('./views/contents/list/list.vue'),
      },
    ],
  },
  {
    path: '/node-networks',
    component: () => import('./views/node-network/node-notwork.vue'),
    meta: { layout: Layout },
    redirect: { name: 'node-networks' },
    children: [
      {
        path: '',
        name: 'node-networks',
        component: () => import('./views/node-network/list/list.vue'),
      },
      {
        path: ':id',
        name: 'node-networks-view',
        component: () => import('./views/node-network/view/view.vue'),
        redirect: { name: 'node-networks-details' },
        children: [
          {
            path: 'details',
            name: 'node-networks-details',
            component: () => import('./views/node-network/view/details/details.vue'),
          },
          {
            path: 'currencies',
            name: 'node-networks-currencies',
            component: () => import('./views/node-network/view/currencies/currencies.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/create-payment',
    name: 'create-payment',
    component: () => import('./views/create-payment/create-payment.vue'),
    meta: { layout: Layout },
  },
  {
    path: '/merchant',
    component: () => import('./views/merchant/merchant.vue'),
    redirect: { name: 'merchant' },
    meta: { layout: Layout },
    children: [
      {
        path: '',
        name: 'merchant',
        component: () => import('./views/merchant/list/list.vue'),
      },
      {
        path: ':id',
        name: 'merchant-edit',
        component: () => import('./views/merchant/view/view.vue'),
        redirect: { name: 'merchant-edit-site-details' },
        children: [
          {
            path: 'site-details',
            name: 'merchant-edit-site-details',
            component: () => import('./views/merchant/view/site-details/site-details.vue'),
          },
          {
            path: 'api-key',
            name: 'merchant-edit-api-key',
            component: () => import('./views/merchant/view/api-key/api-key.vue'),
          },
          {
            path: 'webhooks',
            name: 'merchant-edit-webhooks',
            component: () => import('./views/merchant/view/webhooks/webhooks.vue'),
          },
          {
            path: 'tariffs',
            name: 'merchant-edit-tariffs',
            component: () => import('./views/merchant/view/tariffs/tariffs.vue'),
          },
          {
            path: 'theme',
            name: 'merchant-edit-theme',
            component: () => import('./views/merchant/view/theme/theme.vue'),
          },
          {
            path: 'settings',
            name: 'merchant-edit-settings',
            component: () => import('./views/merchant/view/settings/settings.vue'),
          },
          {
            path: 'administrators',
            name: 'merchant-edit-administrators',
            component: () => import('./views/merchant/view/administrators/administrators.vue'),
          },
          {
            path: 'for-payment-systems',
            name: 'merchant-for-payment-systems',
            component: () => import('./views/merchant/view/for-payment-systems/for-payment-systems.vue'),
          },
          {
            path: 'for-stocks',
            name: 'merchant-for-stocks',
            component: () => import('./views/merchant/view/for-stocks/for-stocks.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/login/login.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('./views/register/register.vue'),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('./views/forgot-password/forgot-password.vue'),
  },
  {
    path: '/reset-password/:reset_token/:email',
    name: 'reset-password',
    component: () => import('./views/reset-password/reset-password.vue'),
  },
  {
    path: '/email/verify',
    name: 'email-verify',
    component: () => import('./views/email-verify/email-verify.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('./views/404/404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BACKOFFICE_BASE_PATH),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/user'];
  debug('Checking route:', { toName: to.name, isAuthenticated, unauthorized });

  if (
    !unauthorized.includes(to.name) &&
    !isAuthenticated
  ) {
    debug('Redirecting to /login');

    if (to.name === 'email-verify') {
      eventBus.emit('error', { meta: { message: 'Email verification failed, authorize before visiting link.' }, errors: [] });
    }

    store.dispatch(`router/${MutationType.SET_ROUTER_LAST}`, '/login');

    return next({ name: 'login' });
  }

  debug('Proceeding to route:', to.fullPath);
  return next();
});

router.afterEach(to => {
  debug('navigated to %s', to.fullPath);
  if (to.name !== 'login') {
    store.dispatch(`router/${MutationType.SET_ROUTER_LAST}`, to.fullPath);
  }
});

export default router;
