import * as MutationType from '@app/store/mutation-types';
import isEqual from 'lodash/isEqual';
import Debug from 'debug';
import { isEmptyArray, isEmptyObject } from '@app/utils/functions.js';

const debug = Debug('store:extensions:filtering'); // eslint-disable-line

export default {
  state: {
    filter: {},
    sort: [],
  },
  actions: {
    filterBy({ commit }, filter) {
      commit(MutationType.SET_FILTERS, filter);
    },
    sortBy({ commit }, sort) {
      commit(MutationType.SET_SORT_BY, sort);
    },
  },
  getters: {
    filter: s => s.filter,
    sort: s => s.sort,
    isFiltered: s => !isEmptyObject(s.filter),
    isSorted: s => !isEmptyArray(s.sort),
    isRequestChanged: s => (query) => {
      const queryFilter = query?.filter ?? {};
      const querySort = query?.sort ?? [];

      return !isEqual(s.filter, queryFilter) || !isEqual(s.sort, querySort) || (query?.force || false);
    },
  },
  mutations: {
    [MutationType.SET_FILTERS](s, filter) {
      s.filter = filter;
    },
    [MutationType.SET_SORT_BY](s, sort) {
      s.sort = sort;
    },
  },
};
