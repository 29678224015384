import * as MutationType from '@app/store/mutation-types';
import { LOADING, RESET_STATE } from '@app/store/extension-loader';
import api from '@app/utils/api-reader';
import merge from 'lodash/merge';

const actions = {
  download({ commit, dispatch, getters }, query = null) {
    if (!getters.data.length) {
      dispatch('start');
      return api
        .get('backoffice/admin/settings', query)
        .then(res => {
          commit(MutationType.GET_SETTINGS, res.data);
          dispatch('stop');
          return res;
        })
        .catch(err => { dispatch('stop'); throw err; });
    }
    return { data: getters.data };
  },
  update({ commit, dispatch }, payload) {
    dispatch('start');

    return api
      .patch('backoffice/admin/settings/update', payload)
      .then(res => {
        commit(MutationType.UPDATE_SETTINGS, res.data);
        dispatch('stop');
        return res;
      })
      .catch(err => { dispatch('stop'); throw err; });
  },
};

const mutations = {
  [MutationType.GET_SETTINGS](s, data) {
    s.data = data;
  },
  [MutationType.UPDATE_SETTINGS](s, data) {
    const index = s.data.findIndex(record => record.key === data.key);

    if (index !== -1) {
      s.data.splice(index, 1, data);
    }
  },
};

export default merge({}, LOADING, RESET_STATE, {
  state: {
    data: [],
  },
  getters: {
    data: s => s.data,
  },
  mutations,
  actions,
  namespaced: true,
});
