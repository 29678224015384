import AuthService from '@app/utils/auth-service';

export default {
  install(app, options) {
    const { store, router } = options || {};

    if (!store || !router) {
      throw new Error('AuthPlugin requires `store` and `router` to be passed.');
    }

    app.config.globalProperties.$authService = new AuthService(store, router);
  },
};
