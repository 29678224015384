import * as MutationType from '@app/store/mutation-types';

const actions = {
  [MutationType.SET_ROUTER_LAST]({ commit }, route) {
    commit(MutationType.SET_ROUTER_LAST, route);
  },
};

const mutations = {
  [MutationType.SET_ROUTER_LAST](s, route) {
    s.last = route;
  },
};

export default {
  state: {
    last: '/',
  },
  getters: {
    last: s => s.last,
  },
  mutations,
  actions,
  namespaced: true,
};
