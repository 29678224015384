import * as MutationType from '@app/store/mutation-types';
import { LOADING, PAGINATOR, FILTER, RESET_STATE } from '@app/store/extension-loader';
import api from '@app/utils/api-reader';
import merge from 'lodash/merge';
import { getQuery } from '@app/store/extensions/get-query.js';

const actions = {
  download({ commit, dispatch, getters }, query = null) {
    query = getQuery(getters, query);

    const shouldFetch =
      (!getters.isLoading && !getters.data.length) ||
      getters.isPaginationChanged(query) ||
      getters.isRequestChanged(query);

    if (shouldFetch) {
      dispatch('start');
      return api
        .get('backoffice/gate/operations', query)
        .then(res => {
          commit(MutationType.GET_OPERATIONS, res.data);
          dispatch('pagination', res.meta.pagination);
          dispatch('sortBy', query?.sort ?? []);
          dispatch('filterBy', query?.filter ?? {});
          dispatch('stop');
          return res;
        })
        .catch(err => { dispatch('stop'); throw err; });
    }
    return { data: getters.data, meta: { pagination: getters.pagination } };
  },
  confirm({ commit, dispatch }, payload) {
    const id = payload.id;
    delete payload.id;

    dispatch('start');
    return api
      .post(`backoffice/gate/operations/${id}/confirm`, payload)
      .then(res => {
        commit(MutationType.CONFIRM_OPERATIONS, res.data);
        dispatch('stop');
        return res;
      })
      .catch(err => { dispatch('stop'); throw err; });
  },
};

const mutations = {
  [MutationType.GET_OPERATIONS](s, data) {
    s.data = data;
  },
  [MutationType.CONFIRM_OPERATIONS](s, data) {
    const index = s.data.findIndex(record => record.id === data.id);

    if (index !== -1) {
      s.data.splice(index, 1, data);
    }
  },
};

export default merge({}, LOADING, FILTER, PAGINATOR, RESET_STATE, {
  state: {
    data: [],
  },
  getters: {
    data: s => s.data,
  },
  mutations,
  actions,
  namespaced: true,
});
