import { createApp } from 'vue';
import App from './components/app/App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify/index';
import pinia from './plugins/vuetify/@core/stores/pinia';
import AuthPlugin from './plugins/auth-plugin';
import WebFontLoader from './plugins/webfontloader';
import * as Sentry from '@sentry/vue';

// styles
import '@vuetify/@core/styles/template/index.scss';
import '@vuetify/@layouts/styles/index.scss';
import '@app/scss/app.scss';

const isLocal = (process.env.NODE_ENV !== 'production');
const vueApp = createApp(App);

WebFontLoader();

if (isLocal) {
  vueApp.config.devtools = true;
  window.localStorage.setItem('debug', '*');
}

if (process.env.SENTRY_DSN) {
  Sentry.init({
    app: vueApp,
    dsn: process.env.SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
    ],
    tracesSampleRate: 0.0,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    telemetry: false,
    beforeSend(event) {
      if (event.level === 'info' || event.level === 'debug') {
        return null; // Ignore low-priority logs
      }
      return event;
    },
  });
}

vueApp
  .use(vuetify)
  .use(router)
  .use(store)
  .use(pinia)
  .use(AuthPlugin, { store, router })
  .mount('#app');
