import * as MutationType from '@app/store/mutation-types';
import { LOADING, RESET_STATE } from '@app/store/extension-loader';
import api from '@app/utils/api-reader';
import merge from 'lodash/merge';
import Debug from 'debug';

const debug = Debug('store:customers'); // eslint-disable-line

const actions = {
  download({ commit, dispatch, getters }, { id, query = null }) {
    if (!getters.data.length) {
      dispatch('start');
      return api
        .get(`backoffice/customers/verifications/${id}`, query)
        .then(res => {
          commit(MutationType.GET_CUSTOMER_VERIFICATIONS, res.data);
          dispatch('stop');
          return res;
        })
        .catch(err => { dispatch('stop'); throw err; });
    }
    return { data: getters.data };
  },
};

const mutations = {
  [MutationType.GET_CUSTOMER_VERIFICATIONS](s, data) {
    s.data = data;
  },
};

export default merge({}, LOADING, RESET_STATE, {
  state: {
    data: [],
  },
  getters: {
    data: s => s.data,
  },
  mutations,
  actions,
  namespaced: true,
});
