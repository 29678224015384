import Debug from 'debug';
import { mapGetters } from 'vuex';
import DefaultLogo from '@vuetify/images/logo.png';

const debug = Debug('assets-mixin');

export const assetsMixin = {
  data() {
    return {
      loadedAssets: {},
      faviconSize: 384,
    };
  },
  computed: {
    ...mapGetters('configuration', [
      'assets',
    ]),
    getLogoStyle() {
      return this.assets.theme?.['merchant-panel']?.['svg-logo'] || null;
    },
    getLogoPath() {
      return this.assets.logo || DefaultLogo;
    },
    getFaviconPath() {
      return this.assets.favicons.find(favicon => favicon.size === this.faviconSize)?.icon || DefaultLogo;
    },
    getLogo() {
      if (this.isLogoImage()) {
        return `<img src="${this.getLogoPath}" alt="${this.assets.title}" height="45" class="img-logo">`;
      }

      return `<div class="svg-logo">${this.getSvgFileContent(this.getLogoPath)}</div>`;
    },
    getFavicon() {
      if (this.isFaviconImage()) {
        return `<img src="${this.getFaviconPath}" alt="${this.assets.title}" height="30" class="img-favicon">`;
      }

      return `<div class="svg-favicon">${this.getSvgFileContent(this.getFaviconPath)}</div>`;
    },
  },
  methods: {
    isLogoImage() {
      return this.getLogoPath.endsWith('.png');
    },
    isFaviconImage() {
      return this.getFaviconPath.endsWith('.png');
    },
    getSvgFileContent(fileUrl) {
      if (this.loadedAssets[fileUrl]) {
        return this.loadedAssets[fileUrl];
      }

      const request = new XMLHttpRequest();
      request.open('GET', fileUrl, false);
      request.send();

      this.loadedAssets[fileUrl] = 'NO FILE';

      if (request.status === 200) {
        this.loadedAssets[fileUrl] = request.responseText;
      }

      return this.loadedAssets[fileUrl];
    },
    injectExternalStyle(styleContent) {
      const existingStyle = document.getElementById('dynamic-svg-style');
      if (existingStyle) {
        existingStyle.innerHTML = styleContent;
      } else {
        const style = document.createElement('style');
        style.id = 'dynamic-svg-style';
        style.type = 'text/css';
        style.innerHTML = styleContent;

        // Append the style element to the document head
        document.head.appendChild(style);
      }
    },
  },
  mounted() {
    debug('mounted.');

    if (this.getLogoStyle) {
      this.injectExternalStyle(this.getLogoStyle);
    }
  },
};
