import ls from '@app/utils/local-storage';
import api from '@app/utils/api-reader';
import * as MutationType from '@app/store/mutation-types';
import { LOADING, RESET_STATE } from '@app/store/extension-loader';
import merge from 'lodash/merge';
import * as DataConstants from '@app/constants/field-data.js';

const actions = {
  setUser({ commit }, user) {
    commit(MutationType.SET_USER, user);
  },
  setUserMerchant({ commit }, merchant) {
    commit(MutationType.SET_USER_MERCHANT, merchant);
  },
  changePassword({ commit, dispatch }, payload) {
    dispatch('start');
    return api
      .post('user/change-password', payload)
      .then(res => {
        commit(MutationType.SET_USER, res.data);
        dispatch('stop');
        return res;
      })
      .catch(err => { dispatch('stop'); throw err; });
  },
  updateSettings({ commit, getters, dispatch }, settings) {
    dispatch('start');
    return api
      .post('user/settings', settings)
      .then(res => {
        commit(MutationType.SET_USER, { ...res.data, token: getters.user?.token });
        dispatch('stop');
        return res;
      })
      .catch(err => { dispatch('stop'); throw err; });
  },
  otpEnable({ commit, getters, dispatch }) {
    if (!getters.otp) {
      dispatch('start');
      return api
        .get('user/otp/enable')
        .then(res => {
          commit(MutationType.SET_USER_OTP_AUTH_DATA, res.data);
          dispatch('stop');
          return res;
        })
        .catch(err => {
          dispatch('stop');
          throw err;
        });
    }
    return { data: getters.otp };
  },
  otpVerify({ commit, getters, dispatch }, payload) {
    dispatch('start');
    return api
      .post('user/otp/verify', payload)
      .then(res => {
        commit(MutationType.SET_USER, { ...getters.user, otp_enabled: 'yes' });
        dispatch('stop');
        return res;
      })
      .catch(err => { dispatch('stop'); throw err; });
  },
  otpDisable({ commit, getters, dispatch }, payload) {
    dispatch('start');
    return api
      .post('user/otp/disable', payload)
      .then(res => {
        commit(MutationType.SET_USER, { ...getters.user, otp_enabled: 'no' });
        dispatch('stop');
        return res;
      })
      .catch(err => { dispatch('stop'); throw err; });
  },
};

const mutations = {
  [MutationType.SET_USER](s, user) {
    s.user = user;
    ls.write('auth:user', user);
  },
  [MutationType.SET_USER_MERCHANT](s, merchant) {
    if (s.user?.administered_merchants === undefined) {
      s.user.administered_merchants = [];
    }
    s.user.administered_merchants.push(merchant);
    ls.write('auth:user', s.user);
  },
  [MutationType.SET_USER_OTP_AUTH_DATA](s, otp) {
    s.otp = otp;
  },
};

export default merge({}, LOADING, RESET_STATE, {
  state: {
    user: ls.read('auth:user') || null,
    otp: null,
  },
  getters: {
    user: s => s.user,
    userMerchants: s => s.user?.administered_merchants || [],
    otp: s => s.otp,
    token: s => s.user?.token || null,
    isSuperuser: s => s.user?.is_superuser === DataConstants.TYPE_YES || false,
    isOtpEnabled: s => s.user?.otp_enabled === DataConstants.TYPE_YES || false,
  },
  mutations,
  actions,
  namespaced: true,
});
