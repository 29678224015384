import * as MutationType from '@app/store/mutation-types';

export default {
  state: {
    isLoading: {},
  },
  getters: {
    isLoading: s => (id) => s.isLoading[id] || false,
  },
  actions: {
    start({ commit }, id) {
      commit(MutationType.IS_LOADING_KEY, { id, status: true });
    },
    stop({ commit }, id) {
      commit(MutationType.IS_LOADING_KEY, { id, status: false });
    },
  },
  mutations: {
    [MutationType.IS_LOADING_KEY](s, { id, status }) {
      s.isLoading[id] = status;
    },
  },
};
