import * as MutationType from '@app/store/mutation-types';
import { LOADING, PAGINATOR } from '@app/store/extension-loader';
import api from '@app/utils/api-reader';
import merge from 'lodash/merge';

const actions = {
  download({ commit, dispatch }, query = null) {
    dispatch('start');
    return api
      .get('backoffice/admin/logs/activity', query)
      .then(res => {
        commit(MutationType.GET_ACTIVITY_LOG, res.data);
        dispatch('pagination', res.meta.pagination);
        dispatch('stop');
        return res;
      })
      .catch(err => { dispatch('stop'); throw err; });
  },
};

const mutations = {
  [MutationType.GET_ACTIVITY_LOG](s, data) {
    s.data = data;
  },
};

export default merge({}, LOADING, PAGINATOR, {
  state: {
    data: [],
  },
  getters: {
    data: s => s.data,
  },
  mutations,
  actions,
  namespaced: true,
});
