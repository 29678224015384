import * as MutationType from '@app/store/mutation-types';
import Debug from 'debug';

const debug = Debug('store:extensions:reset-state'); // eslint-disable-line

export default {
  actions: {
    reset({ commit }, defaultState = { data: [] }) {
      commit(MutationType.RESET_STATE, defaultState);
    },
  },
  mutations: {
    [MutationType.RESET_STATE](s, defaultState) {
      Object.keys(defaultState).forEach(key => {
        s[key] = defaultState[key];
      });
    },
  },
};
