import * as MutationType from '@app/store/mutation-types';
import { LOADING, PAGINATOR } from '@app/store/extension-loader';
import api from '@app/utils/api-reader';
import merge from 'lodash/merge';

const actions = {
  download({ commit, dispatch }, query = null) {
    dispatch('start');
    return api
      .get('backoffice/admin/queue/failed', query)
      .then(res => {
        commit(MutationType.GET_QUEUE_FAILED, res.data);
        dispatch('stop');
        return res;
      })
      .catch(err => { dispatch('stop'); throw err; });
  },
  reQueue({ commit, dispatch }, uuid) {
    dispatch('start');
    return api
      .post('backoffice/admin/queue/requeue', { uuid })
      .then(res => {
        commit(MutationType.GET_QUEUE_REQUEUE, uuid);
        dispatch('stop');
        return res;
      })
      .catch(err => { dispatch('stop'); throw err; });
  },
};

const mutations = {
  [MutationType.GET_QUEUE_FAILED](s, data) {
    s.data = data;
  },
  [MutationType.GET_QUEUE_REQUEUE](s, uuid) {
    s.data = s.data.filter(record => record.uuid !== uuid);
  },
};

export default merge({}, LOADING, PAGINATOR, {
  state: {
    data: [],
  },
  getters: {
    data: s => s.data,
  },
  mutations,
  actions,
  namespaced: true,
});
