import * as MutationType from '@app/store/mutation-types';
import { LOADING } from '@app/store/extension-loader';
import api from '@app/utils/api-reader';
import merge from 'lodash/merge';
import Debug from 'debug';

const debug = Debug('store:configuration'); // eslint-disable-line

const actions = {
  download({ commit, getters, dispatch }, query = null) {
    if (!getters.data.length) {
      dispatch('start');
      return api
        .get('public/configuration', query)
        .then(res => {
          commit(MutationType.GET_CONFIGURATION, res.data);
          dispatch('stop');
          return res;
        })
        .catch(err => { dispatch('stop'); throw err; });
    }
    return { data: getters.data };
  },
};

const mutations = {
  [MutationType.GET_CONFIGURATION](s, data) {
    s.data = data;
  },
};

export default merge({}, LOADING, {
  state: {
    data: [],
  },
  getters: {
    data: s => s.data,
    assets: s => s.data.assets,
    apps: s => s.data.apps,
    api: s => s.data.api,
  },
  mutations,
  actions,
  namespaced: true,
});
