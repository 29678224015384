import * as MutationType from '@app/store/mutation-types';
import { LOADING } from '@app/store/extension-loader';
import api from '@app/utils/api-reader';
import merge from 'lodash/merge';

const actions = {
  initPayment({ commit, dispatch, getters }, payload) {
    if (!getters.data.length) {
      dispatch('start');
      return api
        .post('backoffice/gate/create-payment', payload)
        .then(res => {
          commit(MutationType.GATE_CREATE_PAYMENT, res.data);
          dispatch('stop');
          return res;
        })
        .catch(err => { dispatch('stop'); throw err; });
    }
    return { data: getters.data };
  },
};

const mutations = {
  [MutationType.GATE_CREATE_PAYMENT](s, data) {
    s.data = data;
  },
};

export default merge({}, LOADING, {
  state: {
    data: [],
  },
  getters: {
    data: s => s.data,
  },
  mutations,
  actions,
  namespaced: true,
});
