import Debug from 'debug';
import Layout from '@app/components/layout/Layout.vue';
import LayoutBlank from '@app/components/layout-blank/LayoutBlank.vue';
import { mapActions, mapGetters } from 'vuex';
import { MERCHANT_ENTITY } from '@app/constants/form-data.js';
import * as DataConstants from '@app/constants/field-data.js';
import eventBus from '@app/utils/event-bus';
import OtpModal from '@app/components/otp-modal/OtpModal.vue';

const debug = Debug('app');

export default {
  components: {
    Layout,
    LayoutBlank,
    OtpModal,
  },
  data() {
    return {
      isLayoutReady: false,
      snackHasMessage: false,
      snackMessage: '',
      selectedRecord: MERCHANT_ENTITY,
      statusesFieldOptions: [
        { title: 'Active', value: DataConstants.STATUS_ACTIVE },
        { title: 'Disabled', value: DataConstants.STATUS_DISABLED },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', {
      authUser: 'user',
      authIsSuperuser: 'isSuperuser',
      authIsOtpEnabled: 'isOtpEnabled',
      authUserMerchants: 'userMerchants',
    }),
    ...mapGetters('configuration', {
      configurationIsLoading: 'isLoading',
    }),
    layoutComponent() {
      return this.$route.meta.layout || LayoutBlank;
    },
    isOtpNotEnabled() {
      if (this.authUser) {
        return !this.authIsOtpEnabled;
      }
      return false;
    },
    isZeroMerchantsCreated() {
      if (this.authUser) {
        return !this.authIsSuperuser && this.authIsOtpEnabled && this.authUserMerchants.length < 1;
      }
      return false;
    },
  },
  watch: {
    isZeroMerchantsCreated: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.redirectToMerchant();
        }
      },
    },
    '$route.name'(newRoute) {
      if (this.isZeroMerchantsCreated && newRoute !== 'merchant') {
        this.redirectToMerchant();
      }
      if (this.isOtpNotEnabled && !['opt-and-security', 'email-verify'].includes(newRoute)) {
        this.redirectToOtpEnable();
      }
    },
  },
  methods: {
    ...mapActions('merchants', {
      merchantsCreate: 'create',
    }),
    ...mapActions('configuration', {
      configurationDownload: 'download',
    }),
    redirectToMerchant() {
      eventBus.emit('show-message', {
        meta: { message: 'You must create your first merchant. Redirecting...' },
      });
      setTimeout(() => { this.$router.push({ name: 'merchant' }); }, 1000);
    },
    redirectToOtpEnable() {
      eventBus.emit('show-message', {
        meta: { message: 'To use backoffice you must setup Two-Factor Authorization. Redirecting...' },
      });
      setTimeout(() => { this.$router.push({ name: 'opt-and-security' }); }, 1000);
    },
    prepareLayout() {
      this.isLayoutReady = true;
    },
    createConfirm() {
      this.merchantsCreate(this.selectedRecord)
        .then(res => {
          this.$router.push({ name: 'merchant-edit', params: { id: res.data.id } });
        });
    },
    formatErrors(errors) {
      if (errors && typeof errors === 'object') {
        let formattedErrors = '';

        for (const key in errors) {
          if (Object.prototype.hasOwnProperty.call(errors, key)) {
            formattedErrors += `${key}: ${errors[key].join(', ')}<br />`;
          }
        }

        return formattedErrors.trim();
      } else if (errors?.message) {
        return errors.message;
      }

      return 'An unknown error occurred.';
    },
    handleError(response) {
      this.snackHasMessage = true;
      this.snackMessage = `<b style="color: red;">${response.meta.message}</b>`;
      if (response?.errors) {
        this.snackMessage += `<br/>${this.formatErrors(response.errors)}`;
      }
    },
    handleMessage(response) {
      this.snackHasMessage = true;
      this.snackMessage = `<b>${response.meta.message}</b>`;
      if (response?.data) {
        this.snackMessage += `<br/>${JSON.stringify(response.data)}`;
      }
    },
  },
  mounted() {
    debug('mounted');

    this.configurationDownload()
      .then(res => {
        document.title = `${res.data.assets.title} - BACKOFFICE`;

        res.data.assets.favicons.forEach(favicon => {
          const link = document.createElement('link');
          link.rel = 'icon';
          link.href = favicon.icon;
          link.sizes = `${favicon.size}x${favicon.size}`;

          document.head.appendChild(link);
        });
      });

    eventBus.on('error', this.handleError);
    eventBus.on('show-message', this.handleMessage);
    eventBus.on('enable:otp', this.redirectToOtpEnable);

    // Handle symlink change
    window.addEventListener('error', (event) => {
      if (event.target.tagName === 'SCRIPT' || event.target.tagName === 'LINK') {
        eventBus.emit('show-message', {
          meta: { message: 'New version of the application is available. Page refreshing in 5 seconds...' },
        });
        setTimeout(() => window.location.reload(), 5000);
      }
    }, true);

    this.$nextTick(() => {
      this.prepareLayout();
      if (this.isZeroServiceCreated && this.$route.name !== 'service') {
        this.redirectToMerchant();
      }
    });
  },
};
