import * as DataConstants from '@app/constants/field-data.js';
import DefaultLogo from '@vuetify/images/logo.png';

const isLocal = (process.env.NODE_ENV !== 'production');

export const MERCHANT_WEBHOOK_ENTITY = {
  merchant_id: null,
  event_name: DataConstants.WEBHOOK_TRANSACTION_STATUS,
  template_class: DataConstants.WEBHOOK_TEMPLATE_CLASS_TRANSACTION,
  url: '',
  status: DataConstants.STATUS_ACTIVE,
};

export const COUNTRIES_ENTITY = {
  name: '',
  iso_alpha2: '',
  iso_alpha3: '',
  dialing_code: '',
  regin: '',
  timezone: '',
  status: DataConstants.STATUS_ACTIVE,
};

export const CURRENCY_ENTITY = {
  stock_id: 0,
  name: '',
  code: '',
  raw_code: '',
  fiat: 'yes',
  params: {},
  status: DataConstants.STATUS_ACTIVE,
};

export const CURRENCY_FILTERS = {
  stock_id: null,
  name: null,
  code: null,
  raw_code: null,
  fiat: null,
  status: null,
};

export const TARIFF_ENTITY = {
  action: DataConstants.TARIFF_ACTION_PAY_VIA_PAYMENT_GATE,
  group: 'default',
  parent_id: 0,
  user_profile_id: 0,
  service: DataConstants.TARIFF_SERVICE_PAYMENT_GATEWAY,
  service_id: 0,
  currency_code: 'EUR',
  type: DataConstants.TARIFF_TYPE_FIX,
  fee: DataConstants.DEFAULT_AMOUNT,
  instruction: DataConstants.TARIFF_INSTRUCTION_REQUIRED,
  instruction_rules: [],
  status: DataConstants.STATUS_ACTIVE,
};

export const NODE_NETWORK_ENTITY = {
  name: '',
  alias: '',
  settings: {},
  params: {},
  status: DataConstants.STATUS_ACTIVE,
};

export const MERCHANT_ENTITY = {
  name: '',
  status: DataConstants.STATUS_ACTIVE,
  site_url: '',
  success_url: null,
  return_url: null,
  fail_url: null,
  tariff_group: 'default',
  invoice_from: null,
};

export const CREATE_PAYMENT = {
  meta: {
    signature: null,
    merchant_id: null,
    payment_id: '',
  },
  payment: {
    price: '100.00',
    currency: 'EUR',
  },
  user: {
    country: 'PL',
    first_name: 'Name',
    last_name: 'Surname',
    email: 'test@crypto-fusion.com',
    phone: '+37122283233',
    day_of_birth: '2000-01-01',
    address: null,
    city: null,
    zip: null,
  },
  return: {
    success: null,
    fail: null,
    return: null,
  },
  order: {
    stock_currency_code: null,
    external_address: null,
    chain: null,
  },
  callback: {
    url: null,
  },
};

export const THEME_CONFIGURATION_ENTITY = {
  logo: DefaultLogo,
  theme: [],
  favicons: [
    {
      size: 64,
      icon: DefaultLogo,
    },
    {
      size: 192,
      icon: DefaultLogo,
    },
    {
      size: 384,
      icon: DefaultLogo,
    },
  ],
};

export const SETTINGS_ENTITY = {
  key: '',
  value: '',
};

export const CONTENT_ENTITY = {
  type: '',
  body: '',
};

export const CONFIRM_OPERATION_ENTITY = {
  id: null,
  reference_id: '',
  comment: '',
};

export const TRANSACTION_FILTERS = {
  id: '',
  reference_id: '',
  merchant_id: null,
  amount: {
    key: 'request->payment->price',
    value: '',
  },
  currency_from: {
    key: 'currency_code_from',
    value: null,
  },
  currency_to: {
    key: 'currency_code_to',
    value: null,
  },
  type: null,
  status: null,
  last_error_message: '',
  created_at: {
    from: null,
    to: null,
  },
};

export const OPERATION_FILTERS = {
  id: '',
  transaction_id: '',
  reference_id: '',
  amount: '',
  currency: {
    key: 'currency_code',
    value: null,
  },
  direction: null,
  status: null,
  action: null,
  service_type: {
    key: 'service',
    value: null,
  },
  service: {
    key: 'service_id',
    value: null,
  },
  service_reference_id: '',
  merchant: {
    key: 'merchant_id',
    value: null,
  },
  customer: {
    key: 'user_profile_id',
    value: null,
  },
  error_message: {
    key: 'message',
    value: '',
  },
  created_at: {
    from: null,
    to: null,
  },
};

export const TARIFFS_FILTERS = {
  id: '',
  action: null,
  group: null,
  currency_code: null,
  parent_id: null,
  user_profile_id: null,
  service: null,
  service_id: null,
  type: null,
  fee: null,
  instruction: null,
  status: null,
};

export const USER_REGISTER = {
  name: (isLocal ? 'test' : ''),
  surname: (isLocal ? 'user' : ''),
  email: (isLocal ? 'test@example.com' : ''),
  country: 'LU',
  day_of_birth: (isLocal ? '1990-01-01' : ''),
  phone: (isLocal ? '+3712919788' : ''),
  password: (isLocal ? 'password' : ''),
  password_confirmation: (isLocal ? 'password' : ''),
  terms_and_policy: isLocal,
};

export const USER_FORGOT = {
  email: '',
};

export const USER_RESET = {
  email: '',
  password: '',
  password_confirmation: '',
  token: null,
};

export const MERCHANT_ADMINISTRATOR = {
  user_id: null,
};

export const ACCOUNT_SETTINGS = {
  name: '',
  email: '',
};

export const ACCOUNT_PASSWORD = {
  password_current: '',
  password: '',
  password_confirmation: '',
};

export const OTP_FORM = {
  otp: '',
};

export const NODE_NETWORK_TO_CURRENCY_ENTITY = {
  status: 'active',
  currency_code: null,
};

export const PAYMENT_SYSTEM_TO_CURRENCY_ENTITY = {
  status: 'active',
  currency_code: null,
};

export const PAYMENT_SYSTEM_MERCHANT_OVERRIDE_ENTITY = {
  payment_system_id: null,
  params: {},
  params_schema: {},
  payment_system_merchant_override_id: null,
};

export const STOCK_MERCHANT_OVERRIDE_ENTITY = {
  stock_id: null,
  params: {},
  params_schema: {},
  stock_merchant_override_id: null,
};
