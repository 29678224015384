import * as MutationType from '@app/store/mutation-types';

export default {
  state: {
    isLoading: null,
    loadedAt: null,
  },
  getters: {
    isLoading: s => s.isLoading,
    shouldReload: (s) => {
      if (!s.loadedAt) {
        return false;
      }

      const loadedAt = new Date(s.loadedAt).getTime();
      const now = new Date().getTime();
      const tenMinutes = 10 * 60 * 1000; // 10 minutes in milliseconds

      return (now - loadedAt) > tenMinutes;
    },
  },
  actions: {
    start({ commit }) {
      commit(MutationType.IS_LOADING, true);
    },
    stop({ commit }) {
      commit(MutationType.IS_LOADING, false);
    },
  },
  mutations: {
    [MutationType.IS_LOADING](s, status) {
      s.isLoading = status;
      if (!status) {
        s.loadedAt = new Date().toISOString();
      } else {
        s.loadedAt = null;
      }
    },
  },
};
