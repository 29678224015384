import * as MutationType from '@app/store/mutation-types';
import isEqual from 'lodash/isEqual';
import { isEmptyArray, isEmptyObject } from '@app/utils/functions.js';

export default {
  state: {
    filter: {},
    sort: {},
  },
  actions: {
    filterBy({ commit }, { id, filter }) {
      commit(MutationType.SET_FILTERS, { id, filter });
    },
    sortBy({ commit }, { id, sort }) {
      commit(MutationType.SET_SORT_BY, { id, sort });
    },
  },
  getters: {
    filter: s => (id) => s.filter[id],
    sort: s => (id) => s.sort[id],
    isFiltered: s => (id) => !isEmptyObject(s.filter[id]),
    isSorted: s => (id) => !isEmptyArray(s.sort[id]),
    isRequestChanged: s => (id, query) => {
      const queryFilter = query?.filter ?? {};
      const querySort = query?.sort ?? {};

      return !isEqual(s.filter[id], queryFilter) || !isEqual(s.sort[id], querySort) || (query?.force || false);
    },
  },
  mutations: {
    [MutationType.SET_FILTERS](s, { id, filter }) {
      s.filter = {
        ...s.filter,
        [id]: filter,
      };
    },
    [MutationType.SET_SORT_BY](s, { id, sort }) {
      s.sort = {
        ...s.sort,
        [id]: sort,
      };
    },
  },
};
