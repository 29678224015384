import { defineComponent, h } from 'vue';
import checkboxChecked from '@vuetify/images/svg/checkbox-checked.svg';
import checkboxIndeterminate from '@vuetify/images/svg/checkbox-indeterminate.svg';
import checkboxUnchecked from '@vuetify/images/svg/checkbox-unchecked.svg';
import radioChecked from '@vuetify/images/svg/radio-checked.svg';
import radioUnchecked from '@vuetify/images/svg/radio-unchecked.svg';
import 'remixicon/fonts/remixicon.css';

const createSvgIcon = (svg) => {
  return defineComponent({
    name: 'SvgIcon',
    render() {
      return h('img', { src: svg, alt: '' });
    },
  });
};

const customIcons = {
  'mdi-checkbox-blank-outline': createSvgIcon(checkboxUnchecked),
  'mdi-checkbox-marked': createSvgIcon(checkboxChecked),
  'mdi-minus-box': createSvgIcon(checkboxIndeterminate),
  'mdi-radiobox-marked': createSvgIcon(radioChecked),
  'mdi-radiobox-blank': createSvgIcon(radioUnchecked),
};

const aliases = {
  info: 'ri-error-warning-line',
  success: 'ri-checkbox-circle-line',
  warning: 'ri-alert-line',
  error: 'ri-error-warning-line',
  calendar: 'ri-calendar-2-line',
  collapse: 'ri-arrow-up-s-line',
  complete: 'ri-check-line',
  cancel: 'ri-close-line',
  close: 'ri-close-line',
  delete: 'ri-close-circle-fill',
  clear: 'ri-close-line',
  prev: 'ri-arrow-left-s-line',
  next: 'ri-arrow-right-s-line',
  delimiter: 'ri-circle-line',
  sort: 'ri-arrow-up-line',
  expand: 'ri-arrow-down-s-line',
  menu: 'ri-menu-line',
  subgroup: 'ri-arrow-down-s-fill',
  dropdown: 'ri-arrow-down-s-line',
  edit: 'ri-pencil-line',
  ratingEmpty: 'ri-star-line',
  ratingFull: 'ri-star-fill',
  ratingHalf: 'ri-star-half-line',
  loading: 'ri-refresh-line',
  first: 'ri-skip-back-mini-line',
  last: 'ri-skip-forward-mini-line',
  unfold: 'ri-split-cells-vertical',
  file: 'ri-attachment-2',
  plus: 'ri-add-line',
  minus: 'ri-subtract-line',
  sortAsc: 'ri-arrow-up-line',
  sortDesc: 'ri-arrow-down-line',
};

/* eslint-enable */
export const iconify = {
  component: props => {
    // Load custom SVG directly instead of going through icon component
    if (typeof props.icon === 'string') {
      const iconComponent = customIcons[props.icon];
      if (iconComponent) {
        return h(iconComponent);
      }
    }

    return h(props.tag, {
      ...props,

      // As we are using class based icons
      class: [props.icon],

      // Remove used props from DOM rendering
      tag: undefined,
      icon: undefined,
    });
  },
};

export const icons = {
  defaultSet: 'iconify',
  aliases,
  sets: {
    iconify,
  },
};
