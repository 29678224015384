import Debug from 'debug';
import eventBus from '@app/utils/event-bus';

const debug = Debug('loading-indicator');

export default {
  data() {
    return {
      bufferValue: 20,
      progressValue: 10,
      isFallbackState: false,
      interval: null,
      showProgress: false,
    };
  },
  watch: {
    progressValue() {
      if (this.progressValue > 80 && this.isFallbackState) {
        this.progressValue = 82;
      }
      this.startBuffer();
    },
    isFallbackState() {
      if (this.progressValue > 80 && this.isFallbackState) {
        this.progressValue = 82;
      }
      this.startBuffer();
    },
  },
  methods: {
    startBuffer() {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.progressValue += Math.random() * (15 - 5) + 5;
        this.bufferValue += Math.random() * (15 - 5) + 6;
      }, 800);
    },
    fallbackHandle() {
      this.showProgress = true;
      this.progressValue = 10;
      this.isFallbackState = true;
      this.startBuffer();
    },
    resolveHandle() {
      this.isFallbackState = false;
      this.progressValue = 100;
      setTimeout(() => {
        clearInterval(this.interval);
        this.progressValue = 0;
        this.bufferValue = 20;
        this.showProgress = false;
      }, 300);
    },
    handleError() {
      clearInterval(this.interval);
      this.showProgress = false;
      this.progressValue = 0;
      this.bufferValue = 20;
    },
    startListeningForEvents() {
      eventBus.on('start', this.fallbackHandle);
      eventBus.on('done', this.resolveHandle);
      eventBus.on('error', this.handleError);
    },
    stopListeningForEvents() {
      eventBus.off('start', this.fallbackHandle);
      eventBus.off('done', this.resolveHandle);
      eventBus.off('error', this.handleError);
    },
  },
  mounted() {
    debug('mounted');
    this.startListeningForEvents();
  },
  unmounted() {
    this.stopListeningForEvents();
    clearInterval(this.interval);
  },
};
