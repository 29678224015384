import * as MutationType from '@app/store/mutation-types';

export default {
  state: {
    pagination: {},
  },
  actions: {
    pagination({ commit }, { id, pagination }) {
      commit(MutationType.SET_PAGINATION, { id, pagination });
    },
  },
  getters: {
    pagination: s => (id) => s.pagination[id],
    isPaginationChanged: s => (id, query) => {
      const page = query?.page ? parseInt(query.page, 10) : 1;
      return s.pagination[id]?.current_page !== page;
    },
  },
  mutations: {
    [MutationType.SET_PAGINATION](s, { id, pagination }) {
      s.pagination = {
        ...s.pagination,
        [id]: pagination,
      };
    },
  },
};
