import * as MutationType from '@app/store/mutation-types';
import { LOADING, PAGINATOR, FILTER, RESET_STATE } from '@app/store/extension-loader';
import api from '@app/utils/api-reader';
import merge from 'lodash/merge';
import { getQuery } from '@app/store/extensions/get-query.js';

const actions = {
  download({ commit, dispatch, getters }, query = null) {
    query = getQuery(getters, query);

    const shouldFetch =
      (!getters.isLoading && !getters.data.length) ||
      getters.isPaginationChanged(query) ||
      getters.isRequestChanged(query);

    if (shouldFetch) {
      dispatch('start');
      return api
        .get('backoffice/admin/payment-system', query)
        .then(res => {
          commit(MutationType.GET_PAYMENT_SYSTEMS, res.data);
          dispatch('pagination', res.meta.pagination);
          dispatch('sortBy', query?.sort ?? []);
          dispatch('filterBy', query?.filter ?? {});
          dispatch('stop');
          return res;
        })
        .catch(err => { dispatch('stop'); throw err; });
    }
    return { data: getters.data, meta: { pagination: getters.pagination } };
  },
  create({ commit, dispatch }, payload) {
    dispatch('start');

    return api
      .post('backoffice/admin/payment-system/create', payload)
      .then(res => {
        commit(MutationType.CREATE_PAYMENT_SYSTEM, res.data);
        dispatch('stop');
        return res;
      })
      .catch(err => { dispatch('stop'); throw err; });
  },
  update({ commit, dispatch }, payload) {
    dispatch('start');

    const id = payload.id;
    delete payload.id;

    return api
      .patch(`backoffice/admin/payment-system/update/${id}`, payload)
      .then(res => {
        commit(MutationType.UPDATE_PAYMENT_SYSTEM, res.data);
        dispatch('stop');
        return res;
      })
      .catch(err => { dispatch('stop'); throw err; });
  },
  linkCurrency({ commit, dispatch }, payload) {
    dispatch('start');

    const id = payload.id;
    delete payload.id;

    return api
      .patch(`backoffice/admin/payment-system/update/${id}/link-currency`, payload)
      .then(res => {
        commit(MutationType.UPDATE_PAYMENT_LINK_CURRENCY, res.data);
        dispatch('stop');
        return res;
      })
      .catch(err => { dispatch('stop'); throw err; });
  },
};

const mutations = {
  [MutationType.GET_PAYMENT_SYSTEMS](s, data) {
    s.data = data;
  },
  [MutationType.CREATE_PAYMENT_SYSTEM](s, data) {
    s.data.unshift(data);
  },
  [MutationType.UPDATE_PAYMENT_SYSTEM](s, data) {
    const index = s.data.findIndex(record => record.id === data.id);

    if (index !== -1) {
      s.data.splice(index, 1, data);
    }
  },
  [MutationType.UPDATE_PAYMENT_LINK_CURRENCY](s, data) {
    const index = s.data.findIndex(record => record.id === data.payment_system_id);

    if (index !== -1) {
      const currencyIndex = s.data[index]?.currencies.findIndex(currency => currency.currency_code === data.currency_code);

      if (currencyIndex !== -1) {
        s.data[index].currencies[currencyIndex] = { ...data };
      } else {
        s.data[index].currencies.unshift(data);
      }
    }
  },
};

export default merge({}, LOADING, FILTER, PAGINATOR, RESET_STATE, {
  state: {
    data: [],
  },
  getters: {
    data: s => s.data,
  },
  mutations,
  actions,
  namespaced: true,
});
