// AUTH MODULE
export const SET_USER = 'SET_USER';
export const SET_USER_MERCHANT = 'SET_USER_MERCHANT';
export const SET_USER_OTP_AUTH_DATA = 'SET_USER_OTP_AUTH_DATA';

// ROUTER MODULE
export const SET_ROUTER_LAST = 'SET_ROUTER_LAST';

// TRANSACTIONS
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_TRANSACTION_REQUESTS = 'GET_TRANSACTION_REQUESTS';

// CONFIGURATION
export const GET_CONFIGURATION = 'GET_CONFIGURATION';

// OPERATIONS
export const GET_OPERATIONS = 'GET_OPERATIONS';
export const CONFIRM_OPERATIONS = 'CONFIRM_OPERATIONS';
export const GET_OPERATION_REQUESTS = 'GET_OPERATION_REQUESTS';
export const GET_OPERATION_FEES = 'GET_OPERATION_FEES';

// TARIFFS
export const GET_TARIFFS = 'GET_TARIFFS';
export const CREATE_TARIFFS = 'CREATE_TARIFFS';
export const UPDATE_TARIFFS = 'UPDATE_TARIFFS';
export const DELETE_TARIFFS = 'DELETE_TARIFFS';

// CURRENCIES
export const GET_CURRENCIES = 'GET_CURRENCIES';
export const CREATE_CURRENCY = 'CREATE_CURRENCY';
export const UPDATE_CURRENCY = 'UPDATE_CURRENCY';
export const DELETE_CURRENCY = 'DELETE_CURRENCY';

// COUNTRIES
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const CREATE_COUNTRY = 'CREATE_COUNTRY';
export const UPDATE_COUNTRY = 'UPDATE_COUNTRY';
export const DELETE_COUNTRY = 'DELETE_COUNTRY';

// GATE
export const GATE_CREATE_PAYMENT = 'GATE_CREATE_PAYMENT';

// WEBHOOKS
export const GET_WEBHOOKS = 'GET_WEBHOOKS';
export const UPDATE_WEBHOOKS = 'UPDATE_WEBHOOKS';
export const CREATE_WEBHOOKS = 'CREATE_WEBHOOKS';
export const DELETE_WEBHOOK = 'DELETE_WEBHOOK';

// MERCHANTS
export const GET_MERCHANTS = 'GET_MERCHANTS';
export const UPDATE_MERCHANTS = 'UPDATE_MERCHANTS';
export const CREATE_MERCHANTS = 'CREATE_MERCHANTS';
export const DELETE_MERCHANTS = 'DELETE_MERCHANTS';

// MERCHANT ADMINISTRATORS
export const GET_MERCHANT_ADMINS = 'GET_MERCHANT_ADMINS';
export const ADD_MERCHANT_ADMIN = 'ADD_MERCHANT_ADMIN';
export const DELETE_MERCHANT_ADMIN = 'DELETE_MERCHANT_ADMIN';

// MERCHANT PAYMENT SYSTEMS
export const GET_MERCHANT_PAYMENT_SYSTEMS = 'GET_MERCHANT_PAYMENT_SYSTEMS';
export const CREATE_MERCHANT_PAYMENT_SYSTEM = 'CREATE_MERCHANT_PAYMENT_SYSTEM';
export const UPDATE_MERCHANT_PAYMENT_SYSTEM = 'UPDATE_MERCHANT_PAYMENT_SYSTEM';
export const DELETE_MERCHANT_PAYMENT_SYSTEM = 'DELETE_MERCHANT_PAYMENT_SYSTEM';

// MERCHANT STOCKS
export const GET_MERCHANT_STOCKS = 'GET_MERCHANT_STOCKS';
export const CREATE_MERCHANT_STOCK = 'CREATE_MERCHANT_STOCK';
export const UPDATE_MERCHANT_STOCK = 'UPDATE_MERCHANT_STOCK';
export const DELETE_MERCHANT_STOCK = 'DELETE_MERCHANT_STOCK';

// USERS
export const GET_USERS = 'GET_USERS';

// CUSTOMERS
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_CUSTOMER_VERIFICATIONS = 'GET_CUSTOMER_VERIFICATIONS';

// WIDGETS
export const WIDGET_GET_LAST_MONTH = 'WIDGET_GET_LAST_MONTH';
export const WIDGET_GET_LAST_WEEK = 'WIDGET_GET_LAST_WEEK';
export const WIDGET_GET_ACCOUNT_SUMMARY = 'WIDGET_GET_ACCOUNT_SUMMARY';

// GLOBAL
export const IS_LOADING = 'IS_LOADING';
export const IS_LOADING_KEY = 'IS_LOADING_KEY';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_SORT_BY = 'SET_SORT_BY';
export const SET_FILTERS = 'SET_FILTERS';
export const RESET_STATE = 'RESET_STATE';

// NODE NETWORKS
export const GET_NODE_NETWORKS = 'GET_NODE_NETWORKS';
export const CREATE_NODE_NETWORKS = 'CREATE_NODE_NETWORKS';
export const UPDATE_NODE_NETWORKS = 'UPDATE_NODE_NETWORKS';
export const NODE_NETWORKS_LINK_CURRENCY = 'NODE_NETWORKS_LINK_CURRENCY';

// PAYMENT SYSTEMS
export const GET_PAYMENT_SYSTEMS = 'GET_PAYMENT_SYSTEMS';
export const CREATE_PAYMENT_SYSTEM = 'CREATE_PAYMENT_SYSTEM';
export const UPDATE_PAYMENT_SYSTEM = 'UPDATE_PAYMENT_SYSTEM';
export const UPDATE_PAYMENT_LINK_CURRENCY = 'UPDATE_PAYMENT_LINK_CURRENCY';

// STOCKS
export const GET_STOCKS = 'GET_STOCKS';
export const CREATE_STOCK = 'CREATE_STOCK';
export const UPDATE_STOCK = 'UPDATE_STOCK';

// SERVICES
export const GET_SERVICES = 'GET_SERVICES';

// SETTINGS
export const GET_SETTINGS = 'GET_SETTINGS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';

// CONTENTS
export const GET_CONTENTS = 'GET_CONTENTS';
export const CREATE_CONTENT = 'CREATE_CONTENT';
export const UPDATE_CONTENT = 'UPDATE_CONTENT';
export const DELETE_CONTENT = 'DELETE_CONTENT';

// QUEUE
export const GET_QUEUE_QUEUED = 'GET_QUEUE_QUEUED';
export const GET_QUEUE_FAILED = 'GET_QUEUE_FAILED';
export const GET_QUEUE_REQUEUE = 'GET_QUEUE_REQUEUE';

// ACTIVITY LOG
export const GET_ACTIVITY_LOG = 'GET_ACTIVITY_LOG';

// HTTP REQUESTS
export const GET_HTTP_REQUESTS = 'GET_HTTP_REQUESTS';
