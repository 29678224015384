import * as MutationType from '@app/store/mutation-types';
import { LOADING, PAGINATOR, FILTER, RESET_STATE } from '@app/store/extension-loader';
import api from '@app/utils/api-reader';
import merge from 'lodash/merge';
import Debug from 'debug';
import { getQuery } from '@app/store/extensions/get-query.js';

const debug = Debug('store:transactions'); // eslint-disable-line

const actions = {
  download({ commit, dispatch, getters }, query = null) {
    query = getQuery(getters, query);

    const shouldFetch =
      (!getters.isLoading && !getters.data.length) ||
      getters.isPaginationChanged(query) ||
      getters.isRequestChanged(query);

    if (shouldFetch) {
      dispatch('start');
      return api
        .get('backoffice/gate/transactions', query)
        .then(res => {
          commit(MutationType.GET_TRANSACTIONS, res.data);
          dispatch('pagination', res.meta.pagination);
          dispatch('sortBy', query?.sort ?? []);
          dispatch('filterBy', query?.filter ?? {});
          dispatch('stop');
          return res;
        })
        .catch(err => { dispatch('stop'); throw err; });
    }
    return { data: getters.data, meta: { pagination: getters.pagination } };
  },
};

const mutations = {
  [MutationType.GET_TRANSACTIONS](s, data) {
    s.data = data;
  },
};

export default merge({}, LOADING, FILTER, PAGINATOR, RESET_STATE, {
  state: {
    data: [],
  },
  getters: {
    data: s => s.data,
  },
  mutations,
  actions,
  namespaced: true,
});
