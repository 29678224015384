import * as MutationType from '@app/store/mutation-types';
import { MULTI_PAGINATOR, MULTI_FILTER, RESET_STATE, LOADING_BY_KEY } from '@app/store/extension-loader';
import api from '@app/utils/api-reader';
import merge from 'lodash/merge';
import Debug from 'debug';
import { getQuery } from '@app/store/extensions/get-query.js';

const debug = Debug('store:operation-requests'); // eslint-disable-line

const actions = {
  download({ commit, dispatch, getters }, { id, query = null }) {
    query = getQuery(getters, query, id);

    const shouldFetch =
      (!getters.isLoading(id) && !getters.data(id).length) ||
      getters.isPaginationChanged(id, query) ||
      getters.isRequestChanged(id, query);

    if (shouldFetch) {
      dispatch('start', id);
      return api
        .get(`backoffice/gate/transactions/${id}/requests`, query)
        .then(res => {
          commit(MutationType.GET_TRANSACTION_REQUESTS, { id, data: res.data });
          dispatch('pagination', { id, pagination: res.meta.pagination });
          dispatch('sortBy', { id, sort: query?.sort ?? [] });
          dispatch('filterBy', { id, filter: query?.filter ?? {} });
          dispatch('stop', id);
          return res;
        })
        .catch(err => { dispatch('stop', id); throw err; });
    }
    return { data: getters.data(id), meta: { pagination: getters.pagination(id) } };
  },
};

const mutations = {
  [MutationType.GET_TRANSACTION_REQUESTS](s, { id, data }) {
    s.data = {
      ...s.data,
      [id]: data,
    };
  },
};

export default merge({}, LOADING_BY_KEY, MULTI_FILTER, MULTI_PAGINATOR, RESET_STATE, {
  state: {
    data: {},
  },
  getters: {
    data: s => (id) => s.data[id] || [],
  },
  mutations,
  actions,
  namespaced: true,
});
