import { mapGetters } from 'vuex';
import VerticalNavSectionTitle from '@vuetify/@layouts/components/VerticalNavSectionTitle.vue';
import VerticalNavGroup from '@vuetify/@layouts/components/VerticalNavGroup.vue';
import VerticalNavLink from '@vuetify/@layouts/components/VerticalNavLink.vue';
import Debug from 'debug';

const debug = Debug('nav-items');

export default {
  data() {
    return {
      selectedMerchant: {
        title: 'Show All',
        value: null,
      },
    };
  },
  components: {
    VerticalNavSectionTitle,
    VerticalNavGroup,
    VerticalNavLink,
  },
  computed: {
    ...mapGetters('auth', [
      'user',
      'isSuperuser',
      'userMerchants',
    ]),
    getMenuLinks() {
      return [
        {
          group: { heading: 'Dashboard' },
          items: [
            {
              title: 'Overview',
              icon: 'ri-home-6-line',
              to: { name: 'home' },
              href: this.$router.resolve({ name: 'home', path: '/' }).href,
            },
            {
              title: 'Create Payment',
              icon: 'ri-money-euro-box-line',
              to: { name: 'create-payment' },
              href: this.$router.resolve({ name: 'create-payment', path: '/' }).href,
            },
          ],
        },
        {
          group: { heading: 'Gate' },
          items: [
            {
              title: 'Transactions',
              icon: 'ri-secure-payment-line',
              to: { name: 'transactions' },
              href: this.$router.resolve({ name: 'transactions', path: '/' }).href,
            },
            {
              title: 'Operations',
              icon: 'ri-stack-line',
              to: { name: 'operations' },
              href: this.$router.resolve({ name: 'operations', path: '/' }).href,
            },
            {
              title: 'Customers',
              icon: 'ri-id-card-line',
              to: { name: 'customers' },
              href: this.$router.resolve({ name: 'customers', path: '/' }).href,
            },
            {
              title: 'API Keys & Settings',
              icon: 'ri-settings-3-line',
              to: { name: 'merchant' },
              href: this.$router.resolve({ name: 'merchant', path: '/' }).href,
            },
          ],
        },
      ];
    },
    getSuperAdminMenuLinks() {
      if (!this.isSuperuser) {
        return [];
      }

      return [
        {
          group: { heading: 'Services & Settings' },
          items: [
            {
              title: 'Payment Systems',
              icon: 'ri-bank-line',
              to: { name: 'payment-systems' },
              href: this.$router.resolve({ name: 'payment-systems', path: '/' }).href,
            },
            {
              title: 'Stocks',
              icon: 'ri-stock-line',
              to: { name: 'stocks' },
              href: this.$router.resolve({ name: 'stocks', path: '/' }).href,
            },
            {
              title: 'Node Networks',
              icon: 'ri-node-tree',
              to: { name: 'node-networks' },
              href: this.$router.resolve({ name: 'node-networks', path: '/' }).href,
            },
            {
              title: 'Settings',
              icon: 'ri-settings-5-line',
              to: { name: 'settings' },
              href: this.$router.resolve({ name: 'settings', path: '/' }).href,
            },
          ],
        },
        {
          group: { heading: 'Dictionaries' },
          items: [
            {
              title: 'Tariffs',
              icon: 'ri-pie-chart-line',
              to: { name: 'tariffs' },
              href: this.$router.resolve({ name: 'tariffs', path: '/' }).href,
            },
            {
              title: 'Countries',
              icon: 'ri-earth-line',
              to: { name: 'countries' },
              href: this.$router.resolve({ name: 'countries', path: '/' }).href,
            },
            {
              title: 'Currencies',
              icon: 'ri-currency-line',
              to: { name: 'currencies' },
              href: this.$router.resolve({ name: 'currencies', path: '/' }).href,
            },
            {
              title: 'Content',
              icon: 'ri-text-snippet',
              to: { name: 'contents' },
              href: this.$router.resolve({ name: 'contents', path: '/' }).href,
            },
          ],
        },
        {
          group: { heading: 'Queue' },
          items: [
            {
              title: 'Queued',
              icon: 'ri-fire-line',
              to: { name: 'queue-queued' },
              href: this.$router.resolve({ name: 'queue-queued', path: '/' }).href,
            },
            {
              title: 'Failed',
              icon: 'ri-error-warning-line',
              to: { name: 'queue-failed' },
              href: this.$router.resolve({ name: 'queue-failed', path: '/' }).href,
            },
          ],
        },
        {
          group: { heading: 'Logs' },
          items: [
            {
              title: 'Activity',
              icon: 'ri-user-voice-line',
              to: { name: 'activity-log' },
              href: this.$router.resolve({ name: 'activity-log', path: '/' }).href,
            },
            {
              title: 'HTTP Requests',
              icon: 'ri-rfid-line',
              to: { name: 'http-requests' },
              href: this.$router.resolve({ name: 'http-requests', path: '/' }).href,
            },
          ],
        },
      ];
    },
    merchantFieldOptions() {
      return this.userMerchants.map(merchant => ({
        title: `[${merchant.id}] ${merchant.name}`,
        value: merchant.id,
      }));
    },
  },
  mounted() {
    debug('mounted');
  },
};
