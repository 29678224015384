const prefix = 'cf:merchant:';
const storageKey = name => `${prefix}${name}`;

export default {
  write(name, item) {
    return window.localStorage.setItem(storageKey(name), JSON.stringify(item));
  },
  read(name) {
    return JSON.parse(window.localStorage.getItem(storageKey(name)));
  },
};
