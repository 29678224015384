import NavItems from '@app/components/nav-items/NavItems.vue';
import Footer from '@app/components/footer/Footer.vue';
import LayoutSwitcher from '@app/components/layout-switcher/LayoutSwitcher.vue';
import Profile from '@app/components/profile/Profile.vue';
import VerticalNavLayout from '@vuetify/@layouts/components/VerticalNavLayout.vue';
import Debug from 'debug';
import LoadingIndicator from '@app/components/loading-indicator/LoadingIndicator.vue';
import { assetsMixin } from '@app/mixins/assets.js';
// import { layoutConfig } from '@vuetify/themeConfig.js';
// import { useLayoutConfigStore } from '@vuetify/@layouts/stores/config';

const debug = Debug('layout');

export default {
  mixins: [assetsMixin],
  components: {
    NavItems,
    VerticalNavLayout,
    LoadingIndicator,
    MyFooter: Footer, // conflicts with html tag <footer>
    LayoutSwitcher,
    Profile,
  },
  data() {
    return {
      // layoutConfig,
      // configStore: useLayoutConfigStore,
    };
  },
  mounted() {
    debug('mounted');
  },
};
