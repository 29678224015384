import { createStore } from 'vuex';
import { storeSubscriber } from './store/store-subscriber';

const modules = import.meta.glob('./store/modules/*.js', { eager: true });

const store = createStore({
  modules: Object.fromEntries(
    Object.entries(modules).map(([key, module]) => {
      // Extract module name from file path
      const moduleName = key.split('/').pop().replace('.js', '');
      return [moduleName, module.default];
    }),
  ),
  strict: true,
  plugins: [storeSubscriber],
});

export default store;

// HMR
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    const newModules = import.meta.glob('./modules/*.js', { eager: true });
    store.hotUpdate({
      modules: Object.fromEntries(
        Object.entries(newModules).map(([key, module]) => {
          // Extract module name from file path
          const moduleName = key.split('/').pop().replace('.js', '');
          return [moduleName, module.default];
        }),
      ),
    });
  });
}
